import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

import { Button } from '@trussworks/react-uswds';
import TextAreaInput from '@components/new-inputs/textAreaInput';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import './BannerSettings.scss';

const schema = yup.object().shape({
  banner: yup.string().required('Field is required'),
});

const BannerSettings = connect(
  'doFetchBannerInfo',
  'doUpdateBannerText',
  'doUpdateBannerStatus',
  'selectBannerData',
  ({
    doFetchBannerInfo,
    doUpdateBannerText,
    doUpdateBannerStatus,
    bannerData
  }) => {
    const defaultValues = {
      banner: bannerData?.banner ?? null,
      isBannerOn: bannerData?.isBannerOn ?? false,
    };

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur', stateOptions: [] });
    const { formState: { isValid }, watch, trigger, setValue } = methods;

    const banner = watch('banner');
    const isBannerOn = watch('isBannerOn');

    const handleSubmit = () => {
      // Update banner text
      if (isValid) {
        doUpdateBannerText(banner);
      } else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    const handleBannerStatus = (val) => {
      setValue('isBannerOn', val);
      doUpdateBannerStatus(val);
    };

    useEffect(() => {
      doFetchBannerInfo();
    }, [isBannerOn, doFetchBannerInfo]);

    return (
      <FormProvider {...methods}>
        <div className='row'>
          <div className='col-6'>
            <TextAreaInput name='banner' label='Banner Message' required />
          </div>
          <div className='col-6' style={{ position: 'relative' }}>
            <Button
              className='mt-4'
              title='Update Banner'
              size='small'
              onClick={() => handleSubmit()}>
              Update Banner
            </Button> 
          </div>
        </div>
        <div className='row'>
          <div className='col' >
            <p className='banner-status-text'>
              <span className='mr-1'>Current Status:</span>
              <span className='mr-3' style={{ color: isBannerOn === true ? 'green' : 'red' }}><b>{isBannerOn === true ? 'Enabled' : 'Disabled'}</b></span>
              <Switch height={25} onChange={handleBannerStatus} checked={isBannerOn} />
            </p>
          </div>
        </div>
      </FormProvider>
    );
  });

export default BannerSettings;

