import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import Icon from '@mdi/react';
import { AgGridReact } from 'ag-grid-react';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import { Alert } from '@trussworks/react-uswds';

import FieldHeader from '@forms/components/Form/FieldHeader';
import ContactFields from '@components/new-inputs/contactFieldsnew';
import Card from '@components/card/card';
import { Button } from '@trussworks/react-uswds';
import EditCellRenderer from '@forms/components/gridCellRenderers/editCellRender';
import AddAgentModal from '@forms/components/modals/AddAgentModal';
import SelectInput from '@components/new-inputs/selectInput';
import TextAreaInput from '@components/new-inputs/textAreaInput';
import ValidationCard from '@forms/components/Form/ValidationCard';
import DragInput from '@components/drag-input/dragInput';
import LinkButton from '@components/link/linkButton';
import ErrorSummary from '@components/error-summary/ErrorSummary';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import useErrorFocus from '@src/customHooks/useErrorFocus';
import { ContactTypes, ContactsFormNames, FileTypes, ProfileRoles, TemplateFiles } from '@src/utils/enums';
import { addressRegex, addressTwoRegex, emailRegex, phoneRegex, usPhoneRegex, countryCodeRegex, phoneExtRegex, cityRegex, zipCodeRegex } from '../../../../utils/regex';
import { addUserProfileAgent, updateUserProfileAgent, userProfileAgentUsed, updateGPAgentsValue } from '../../components/modals/_shared/UserProfileAgent';
import { stripUncompletedContacts, formatUSPhoneNumber } from '@src/utils/helpers';

import '@styles//index.scss';

export const PermitsFormContactsMetadata = {
  sectionName: 'Contact Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const PermitsFormContacts = connect(
  'doDownloadFile',
  'doDownloadPopulatedFile',
  'doDeleteFile',
  'doResetFileSection',
  'doClearContactType',
  'doUpdateSectionValidity',
  'doModalOpen',
  'doUpdateSelectedFiles',
  'doUpdateRequestObject',
  'doUpdateRequestContacts',
  'doUpdatePermitRequest',
  'doSaveTable',
  'selectTableData',
  'selectRequestAPIData',
  'selectAgentAuthorizationFile',
  'selectRightOfEntryFile',
  'selectUserProfileData',
  'selectSteps',
  'selectActiveStep',
  'selectTotalFileSize',
  'selectMaxTotalFileSize',
  'selectMaxFileCount',
  'selectSelectedFiles',
  ({
    doDownloadFile,
    doDownloadPopulatedFile,
    doDeleteFile,
    doResetFileSection,
    doClearContactType,
    doUpdateSectionValidity,
    doModalOpen,
    doUpdateSelectedFiles,
    doUpdateRequestObject,
    doUpdateRequestContacts,
    doUpdatePermitRequest,
    doSaveTable,
    tableData,
    requestAPIData,
    agentAuthorizationFile,
    rightOfEntryFile,
    userProfileData,
    steps,
    activeStep,
    totalFileSize,
    maxTotalFileSize,
    maxFileCount,
    selectedFiles,
    edit,
    isReadOnly,
    stepNo,
    componentID
  }) => {
    const [rowData, setRowData] = useState([]);
    const [isUserProfileAgentUsed, setIsUserProfileAgentUsed] = useState(false);

    const [ROEFile, setROEFile] = useState([]);
    const [isROEMultiple, setIsROEMultiple] = useState(false);
    const [isROEOverTotalFileLimit, setIsROEOverTotalFileLimit] = useState(false);
    const [isROEOverFileCountLimit, setIsROEOverFileCountLimit] = useState(false);

    const [agentAuthFile, setAgentAuthFile] = useState([]);
    const [isAAMultiple, setIsAAMultiple] = useState(false);
    const [isAAOverTotalFileLimit, setIsAAOverTotalFileLimit] = useState(false);
    const [isAAOverFileCountLimit, setIsAAOverFileCountLimit] = useState(false);
    const applicantContact = useMemo(() => {
      const contacts = requestAPIData?.request?.contacts || [];
      return contacts.find((contact) => contact.contactType === ContactTypes.Applicant);
    }, [requestAPIData?.request?.contacts]);
    const hasHiredAgent = requestAPIData?.request?.permits?.[0]?.hasHiredAgent ? 'true' : (userProfileData.role === ContactTypes.Agent ? 'true' : 'false');
    const agentsTableLength = rowData?.length;

    const schema = (agentsTableLength) => yup.object().shape({
      firstName: yup.string().required('Field is required'),
      lastName: yup.string().required('Field is required'),
      address: yup.string().required('Field is required').matches(addressRegex, 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed'),
      addressTwo: yup.string().nullable().matches(addressTwoRegex, 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed'),
      city: yup.string().matches(cityRegex, 'Field is invalid').required('Field is required'),
      state: yup.string().when('country', { is: 'US', then: () => yup.string().required('Please select an option'), otherwise: () => yup.string().required('Please select an option') }),
      zipcode: yup.string().when('country', { is: 'US', then: () => yup.string().matches(zipCodeRegex, 'Field is invalid'), otherwise: () => yup.string().nullable() }),
      country: yup.string().required('Please select an option'),
      phoneOneType: yup.string().required('Please select an option'),
      phoneOneCountryCode: yup.string().required('Field is required').matches(countryCodeRegex, 'Field is invalid'),
      phoneOne: yup.string().when('phoneOneCountryCode', {
        is: '1', then: () => yup.string().required('Field is required').matches(usPhoneRegex, 'Field is invalid'),
        otherwise: () => yup.string().when('phoneOneCountryCode', { is: val => val !== '1', then: () => yup.string().required('Field is required').matches(phoneRegex, 'Field is invalid') })
      }).required('Field is required'),
      phoneOneExtension: yup.string().nullable().when('phoneOneType', { is: 'Business', then: () => yup.string().matches(phoneExtRegex, 'Field is invalid') }),
      showPhoneTwo: yup.boolean().nullable(),
      phoneTwoType: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Please select an option') }),
      phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Field is required').matches(countryCodeRegex, 'Field is invalid') }),
      phoneTwo: yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
        is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true, then: () => yup.string().required('Field is required').matches(usPhoneRegex, 'Field is invalid'),
        otherwise: () => yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], { is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true, then: () => yup.string().required('Field is required').matches(phoneRegex, 'Field is invalid') })
      }),
      phoneTwoExtension: yup.string().nullable().when(['showPhoneTwo', 'phoneTwoType'], { is: (show, type) => show && type === 'Business', then: () => yup.string().nullable().matches(phoneExtRegex, { message: 'Field is invalid', excludeEmptyString: true }) }),
      faxPhone: yup.string().matches(phoneRegex, { message: 'Field is invalid', excludeEmptyString: true }).nullable(),
      emailAddress: yup.string().required('Field is required').matches(emailRegex, 'Field is invalid'),
      agentsTable: yup.boolean().when('agents', { is: (val) => val === 'true' && agentsTableLength < 1, then: () => yup.boolean().required('Agents: At least one agent is required') }),
      agents: yup.string().required('Please select an option'),
      agentAuth: yup.string().when('agents', { is: 'true', then: () => yup.string().required('Please provide an Agent Authorization Form') }),
    });


    const defaultValues = {
      salutation: applicantContact?.salutation ?? '',
      firstName: applicantContact?.firstName ?? '',
      middleName: applicantContact?.middleName ?? '',
      lastName: applicantContact?.lastName ?? '',
      address: applicantContact?.address ?? '',
      addressTwo: applicantContact?.addressTwo ?? '',
      city: applicantContact?.city ?? '',
      state: applicantContact?.state ?? '',
      zipcode: applicantContact?.zipcode ?? '',
      country: applicantContact?.country ?? 'US',
      phoneOneType: applicantContact?.phoneOneType ?? '',
      phoneOneCountryCode: applicantContact?.phoneOneCountryCode ?? '1',
      phoneOne: applicantContact?.phoneOne ? (applicantContact?.phoneOneCountryCode === '1' ? formatUSPhoneNumber(applicantContact.phoneOne) : applicantContact?.phoneOne) : '',
      phoneOneExtension: applicantContact?.phoneOneExtension ?? '',
      phoneTwoType: applicantContact?.phoneTwoType ?? '',
      phoneTwoCountryCode: applicantContact?.phoneTwoCountryCode ?? '',
      phoneTwo: applicantContact?.phoneTwo ? (applicantContact?.phoneTwoCountryCode === '1' ? formatUSPhoneNumber(applicantContact.phoneTwo) : applicantContact?.phoneTwo) : '',
      phoneTwoExtension: applicantContact?.phoneTwoExtension ?? '',
      faxPhone: applicantContact?.faxPhone ?? '',
      emailAddress: applicantContact?.emailAddress ?? '',
      company: applicantContact?.company ?? '',
      agents: hasHiredAgent,
      agentAuthComments: requestAPIData?.request?.agentAuthComments ?? '',
      ownerEntryComments: requestAPIData?.request?.ownerEntryComments ?? '',
    };

    const methods = useForm({ resolver: yupResolver(schema(agentsTableLength)), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { isValid, errors }, watch, trigger, getValues, setValue, setError, clearErrors } = methods;


    const agents = watch('agents');
    const agentAuthComments = watch('agentAuthComments');
    const latestvalues = watch();
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const ownerEntryComments = watch('ownerEntryComments');

    const columnDefs = [
      { field: 'actions', headerName: 'Actions', width: 65, cellRenderer: 'editRowRenderer', cellRendererParams: { doModalOpen: doModalOpen, modalComponent: AddAgentModal, edit: true, setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly, userProfileKey: { firstName: userProfileData.firstName, lastName: userProfileData.lastName, email: userProfileData.emailAddress } } },
      { field: 'firstName', headerName: 'First Name', flex: 1, resizable: true },
      { field: 'middleName', headerName: 'Middle Name', flex: 1, resizable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, resizable: true },
      { field: 'address', headerName: 'Address 1', flex: 1, resizable: true },
      { field: 'city', flex: 1, resizable: true },
      { field: 'state', flex: 1, maxWidth: 70 },
      { field: 'country', flex: 1, maxWidth: 70 },
    ];

    useEffect(() => {
      doUpdatePermitRequest({ hasHiredAgent: hasHiredAgent });
    }, [doUpdatePermitRequest, hasHiredAgent]);

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.Agent);
      setIsUserProfileAgentUsed(false);
    }, [doClearContactType]);

    const handleAgentChange = (e) => {
      const stringToBool = e?.target?.value === 'true' ? true : false;
      doUpdatePermitRequest({ hasHiredAgent: stringToBool });
    };

    useEffect(() => {
      setIsUserProfileAgentUsed(userProfileAgentUsed(rowData, userProfileData?.firstName, userProfileData?.lastName, userProfileData?.emailAddress));
    }, [rowData, userProfileData]);

    useEffect(() => {
      // Load tables from database
      let initialRowData = requestAPIData?.request?.contacts?.filter((contact) => contact.contactType === ContactTypes.Agent);
      // If country is null, default to US
      initialRowData = initialRowData?.map(contact => contact.country === null ? { ...contact, country: 'US' } : contact);
      if (initialRowData) {
        //Update agents array if user profile agent exists
        if (userProfileAgentUsed(initialRowData, userProfileData?.firstName, userProfileData?.lastName, userProfileData?.emailAddress)) {
          initialRowData = updateUserProfileAgent(initialRowData, userProfileData);
        }
        updateGPAgentsValue(userProfileData?.role, hasHiredAgent, setValue, doUpdatePermitRequest);
        setRowData(initialRowData);
      }
    }, [requestAPIData, userProfileData, setValue, setRowData, hasHiredAgent, doUpdatePermitRequest]);

    useEffect(() => {
      setValue('agentAuth', agentAuthorizationFile?.[0]?.fileName, { shouldValidate: true });
    }, [agentAuthorizationFile, setValue]);

    useEffect(() => {
      // Right of Entry File validations
      setIsROEMultiple(ROEFile?.length > 1 ? true : false);
      setIsROEOverTotalFileLimit(((isNaN(totalFileSize) ? 0 : totalFileSize) + ROEFile?.[0]?.size > maxTotalFileSize) ? true : false);
      setIsROEOverFileCountLimit((selectedFiles?.length + 1 > maxFileCount) ? true : false);
    }, [ROEFile, setIsROEMultiple, setIsROEOverFileCountLimit, setIsROEOverTotalFileLimit, maxFileCount, maxTotalFileSize, selectedFiles?.length, totalFileSize]);

    useEffect(() => {
      // Agent Auth File validations
      setIsAAMultiple(agentAuthFile?.length > 1 ? true : false);
      setIsAAOverTotalFileLimit(((isNaN(totalFileSize) ? 0 : totalFileSize) + agentAuthFile?.[0]?.size > maxTotalFileSize) ? true : false);
      setIsAAOverFileCountLimit((selectedFiles?.length + 1 > maxFileCount) ? true : false);
    }, [agentAuthFile, setIsAAMultiple, setIsAAOverFileCountLimit, setIsAAOverTotalFileLimit, maxFileCount, maxTotalFileSize, selectedFiles?.length, totalFileSize]);

    useEffect(() => {
      const doSelectFile = () => {
        const RoEFile = {
          file: ROEFile?.[0],
          section: ContactsFormNames.RightOfEntry,
          fileName: ROEFile?.[0]?.name,
          componentID: componentID
        };
        doUpdateSelectedFiles(RoEFile);
      };
      // Set selected file if valid
      doResetFileSection(ContactsFormNames.RightOfEntry);
      (ROEFile?.length > 0 && !isROEMultiple && !isROEOverTotalFileLimit && !isROEOverFileCountLimit) && doSelectFile();
    }, [ROEFile, isROEMultiple, isROEOverTotalFileLimit, isROEOverFileCountLimit, doResetFileSection, componentID, doUpdateSelectedFiles]);

    useEffect(() => {
      const doSelectFile = () => {
        const agentFile = {
          file: agentAuthFile?.[0],
          section: ContactsFormNames.AgentAuthorization,
          fileName: agentAuthFile?.[0]?.name,
          componentID: componentID
        };
        doUpdateSelectedFiles(agentFile);
      };
      // Set selected file if valid
      doResetFileSection(ContactsFormNames.AgentAuthorization);
      (agentAuthFile?.length > 0 && !isAAMultiple && !isAAOverTotalFileLimit && !isAAOverFileCountLimit) && doSelectFile();
    }, [agentAuthFile, isAAMultiple, isAAOverTotalFileLimit, isAAOverFileCountLimit, doResetFileSection, componentID, doUpdateSelectedFiles]);

    useEffect(() => {
      const agentAuthValid = agents === 'false' || (agentAuthorizationFile?.length > 0 && rowData?.length > 0);
      const valid = isValid && agentAuthValid;
      doUpdateSectionValidity(PermitsFormContactsMetadata.sectionName, valid ? true : false, stepNo, isReadOnly);
    }, [isValid, agentAuthorizationFile, agents, rowData, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      doUpdateRequestObject({ ownerEntryComments: ownerEntryComments });
    }, [ownerEntryComments, doUpdateRequestObject]);

    useEffect(() => {
      const { agents, agentAuth, agentAuthComments, propertyowners, showPhoneTwo, ...applicantFields } = latestvalues;
      const applicant = {
        ...applicantFields,
        contactID: applicantContact?.contactID ?? undefined,
        requestID: applicantContact?.requestID ?? undefined,
        version: applicantContact?.version ?? undefined,
        contactType: ContactTypes.Applicant,
        createdBy: applicantContact?.createdBy ?? undefined,
        phoneOne: applicantFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
        phoneTwo: applicantFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
      };
      const contacts = [applicant, ...rowData, ...tableData?.adjoiningPropOwner];
      doUpdateRequestContacts(stripUncompletedContacts(contacts));
    }, [rowData, getValues, latestvalues, doUpdateRequestContacts, applicantContact, tableData?.adjoiningPropOwner]);

    useEffect(() => {
      doUpdateRequestObject({ agentAuthComments: agentAuthComments });
    }, [agentAuthComments, doUpdateRequestObject]);

    // Reset Files if section is hidden
    useEffect(() => {
      if (agents === 'false') {
        clearRowData();
        doResetFileSection(ContactsFormNames.AgentAuthorization, true);
        doResetFileSection(ContactsFormNames.RightOfEntry, true);
      }
    }, [agents, clearRowData, doResetFileSection]);

    useEffect(() => {
      rowData && doSaveTable('agents', rowData);
    }, [rowData, doSaveTable]);

    useEffect(() => {
      if (agents === 'true' && rowData?.length < 1) {
        setError('agentsTable', { type: 'custom', message: 'Agents: At least one agent is required' });
      }
      else {
        clearErrors('agentsTable');
      }

    }, [agents, rowData?.length, setError, clearErrors]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        <>
          {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
            <ErrorSummary errors={errors} isValid={isValid} sectionNo={stepNo} />
          }
          <FieldHeader
            text='Contact Information'
            subtext='Provide contact information for the applicant and the agent if applicable. The applicant is the individual
            or entity submitting the delineation report and/or requesting the
            jurisdictional determination. The agent is a third-party that has
            been retained by the applicant to act on their behalf in submitting
            this request to the U.S. Army Corps of Engineers. If you are an agent you must provide an Agent Authorization Form.
            Download an Agent Authorization form and upload the completed document in the file upload box below.'
          >
          </FieldHeader>
          {/* Contact Section comes first when role is not agent  */}
          {userProfileData?.role !== ProfileRoles.Agent && (
            <ContactFields type='applicant' label='Applicant' edit={edit} isReadOnly={isReadOnly} showButton />
          )}

          <FieldHeader text='Agents' />
          <div className='ml-2 mb-2' id='agentsTable'>
            <SelectInput boolean name='agents' label='Has the applicant hired an agent to complete the application process?' required className='w-50' readOnly={isReadOnly || userProfileData?.role === ContactTypes.Agent} onChange={handleAgentChange} >
              <option key='2' value={true}>Yes</option>
              <option key='3' value={false}>No</option>
            </SelectInput>
          </div>
        </>

        {
          (agents === 'true') && (
            <>
              {/* <div className='ml-2 mb-2'>
              <Alert type='info' slim >
                <p>Note: If a site visit is needed, you, as the agent must also provide a <LinkButton onClick={() => doDownloadFile(TemplateFiles.RightOfEntry, FileTypes.Template)} title='Right of Entry form' content='Right of Entry form' />.</p>
                <p>For multiple property owners, please consolidate right-of-entry forms into one file before uploading. Alternatively, you can upload additional right-of-entry forms later in the Supporting Documentation section.</p>
                <b>Individual files cannot exceed 100MB, and in total cannot exceed 500MB per save.</b>
              </Alert>
              <div className='row mt-1'>
                <div className='col'>
                  <DragInput
                    name='rightOfEntry'
                    text='Click to browse or drag and drop your Right-Of-Entry Form(s) here.'
                    accept={{ 'application/pdf': ['.pdf'], 'application/docx': ['.docx'] }}
                    onChange={setROEFile}
                    isDisabled={rightOfEntryFile?.[0]?.key || isReadOnly}
                    value={rightOfEntryFile}
                    doDownloadFile={() => doDownloadFile(rightOfEntryFile?.[0]?.fileName, FileTypes.Request)}
                    doDeleteFile={() => doDeleteFile(rightOfEntryFile?.[0]?.fileName, componentID)}
                    isReadOnly={isReadOnly}
                  />
                  {isROEMultiple && <ValidationCard message='Multiple files were selected. Please select only 1 file' />}
                  {isROEOverTotalFileLimit && <ValidationCard message='Only 500MB of files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_size_error' />}
                  {isROEOverFileCountLimit && <ValidationCard message='Only 50 files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_count_error' />}
                </div>
              </div>
            </div> */}
              <Card>
                <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
                  <div className='d-flex justify-content-center col-3'>
                    <Button
                      className='add-agent-button'
                      title='Add an Agent'
                      size='small'
                      onClick={() => doModalOpen(AddAgentModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })}
                      disabled={isReadOnly}
                    >
                      <Icon className='mr-1' path={mdiAccountPlus} size={'16px'} />
                    Add an Agent
                    </Button> 
                  </div>
                  {userProfileData.role === ContactTypes.Agent &&
                    <div className='d-flex justify-content-center col-3'>
                      <Button
                        className={`table-btn-profile ${(isUserProfileAgentUsed || isReadOnly) ? 'disabled' : 'hover'}`}
                        title='Use Profile Data'
                        size='small'
                        onClick={() => addUserProfileAgent(userProfileData, rowData, setRowData, isUserProfileAgentUsed, setIsUserProfileAgentUsed)}
                        disabled={isReadOnly || isUserProfileAgentUsed}
                      >
                      Use Profile Data
                      </Button> 
                    </div>}
                  <div className='d-flex justify-content-center col-3'>
                    <Button
                      className={`clear-table-button ${(rowData.length === 0 || isReadOnly) ? 'disabled' : 'hover'}`}
                      title='Clear Agents'
                      size='small'
                      onClick={() => clearRowData('agent')}
                      disabled={rowData.length > 0 ? isReadOnly : true}
                    >
                      <Icon path={mdiCloseOctagon} size={'16px'} />
                    Clear Agents
                    </Button> 
                  </div>
                </div>
                <div className='ag-theme-balham' style={{ height: 400 }}>
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    pagination={true}
                    paginationAutoPageSize={true}
                    rowHeight={35}
                    gridOptions={{
                      alwaysShowVerticalScroll: true
                    }}
                    components={{
                      'editRowRenderer': EditCellRenderer,
                    }}
                    suppressClickEdit
                  ></AgGridReact>
                </div>
              </Card>

              <label className='h6 border-bottom w-100 pb-2 mt-3' htmlFor='agentAuth'>Agent Authorization<span className='asterisk-color'>*</span></label>
              <Alert type='info'>The agent must provide an Agent Authorization form from the applicant.<p>
                <LinkButton onClick={() => doDownloadFile(TemplateFiles.AgentAuthorization, FileTypes.Template)} title='Download a blank Agent Authorization form' content='Download a blank Agent Authorization form' /> to complete or <LinkButton onClick={() => doDownloadPopulatedFile(ContactsFormNames.AgentAuthorization, TemplateFiles.AgentAuthorization)} title='download an Agent Authorization form populated' content='download an Agent Authorization form populated' /> with the information you entered. Note that both documents will need to be signed by the applicant prior to submittal.</p><b>Individual files cannot exceed 100MB, and in total cannot exceed 500MB per save.</b></Alert>
              <div className='row mt-1 mb-3'>
                <div className='col'>
                  <DragInput name='agentAuth' text='Click to browse or drag and drop your Agent Authorization Form here.' accept={{ 'application/pdf': ['.pdf'], 'application/docx': ['.docx'] }} onChange={setAgentAuthFile} isDisabled={agentAuthorizationFile?.[0]?.key || isReadOnly} value={agentAuthorizationFile} doDownloadFile={() => doDownloadFile(agentAuthorizationFile?.[0]?.fileName, FileTypes.Request)} doDeleteFile={() => doDeleteFile(agentAuthorizationFile?.[0]?.fileName, componentID)} isReadOnly={isReadOnly} required />
                  {isAAMultiple && <ValidationCard message='Multiple files were selected. Please select only 1 file' />}
                  {isAAOverTotalFileLimit && <ValidationCard message='Only 500MB of files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_size_error' />}
                  {isAAOverFileCountLimit && <ValidationCard message='Only 50 files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_count_error' />}
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextAreaInput name='agentAuthComments' label='Agent Authorization Comment' readOnly={isReadOnly} />
                </div>
              </div>

              <label className='h6 border-bottom w-100 pb-2 mt-3' htmlFor='rightOfEntry'>Right of Entry</label>
              <Alert type='info' slim >
                <p>Note: If a site visit is needed, you, as the agent must also provide a <LinkButton onClick={() => doDownloadFile(TemplateFiles.RightOfEntry, FileTypes.Template)} title='Right of Entry form' content='Right of Entry form' />.</p>
                <p>For multiple property owners, please consolidate right-of-entry forms into one file before uploading. Alternatively, you can upload additional right-of-entry forms later in the Supporting Documentation section.</p>
                <b>Individual files cannot exceed 100MB, and in total cannot exceed 500MB per save.</b>
              </Alert>
              <div className='row mt-1'>
                <div className='col'>
                  <DragInput
                    name='rightOfEntry'
                    text='Click to browse or drag and drop your Right-Of-Entry Form(s) here.'
                    accept={{ 'application/pdf': ['.pdf'], 'application/docx': ['.docx'] }}
                    onChange={setROEFile}
                    isDisabled={rightOfEntryFile?.[0]?.key || isReadOnly}
                    value={rightOfEntryFile}
                    doDownloadFile={() => doDownloadFile(rightOfEntryFile?.[0]?.fileName, FileTypes.Request)}
                    doDeleteFile={() => doDeleteFile(rightOfEntryFile?.[0]?.fileName, componentID)}
                    isReadOnly={isReadOnly}
                  />
                  {isROEMultiple && <ValidationCard message='Multiple files were selected. Please select only 1 file' />}
                  {isROEOverTotalFileLimit && <ValidationCard message='Only 500MB of files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_size_error' />}
                  {isROEOverFileCountLimit && <ValidationCard message='Only 50 files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_count_error' />}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <TextAreaInput name='ownerEntryComments' label='Right of Entry Comment' readOnly={isReadOnly} />
                </div>
              </div>

              {/* Contact Section comes after Agent Section when role is agent */}
              {userProfileData.role === ProfileRoles.Agent && (
                <div className='ml-2 mb-2'>
                  <ContactFields type='applicant' label='Applicant' edit={edit} isReadOnly={isReadOnly} showButton />
                </div>
              )}
            </>
          )
        }
      </FormProvider >
    );
  }
);
PermitsFormContacts.metadata = PermitsFormContactsMetadata;

export default PermitsFormContacts;
