import { toast } from 'react-toastify';
import statusModal from '../app-pages/Forms/components/modals/statusModal';
import submissionModal from '../app-pages/Forms/components/modals/submission';

const preAppFormDataBundle = {
  name: 'preAppFormData',

  getReducer: () => {
    const initialState = {
      preApplications: [],
      errors: {}
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case 'RESET_PRE_APP':
          return initialState;
        case 'UPDATE_PRE_APP':
          return { ...state, preApplications: [{ ...state.preApplications[0], ...payload }] };
        case 'UPDATE_PRE_APP_ERRORS':
          return { ...state, errors: payload };
        default:
          return state;
      };
    };
  },

  selectPreAppData: (state) => state.preAppFormData.preApplications[0],
  selectPreAppID: (state) => state.preAppFormData.preApplications[0]?.preAppID,
  selectPreAppErrors: (state) => state.preAppFormData.errors,

  doFetchPreApp: (projectID, requestID, preAppID, requestStatus, isGenerate = false) => ({ apiGet, dispatch, store }) => {
    !isGenerate && store.doSetLoadingState(true);
    !isGenerate && store.doSetLoadingMessage('Retrieving Pre-application Request Information...');

    store.doResetPreAppRequest();
    store.doResetRequestData();

    const uri = '/api/PreApplication/getPreApplication?' + new URLSearchParams({
      projectID,
      requestID,
      preAppID,
    });

    return apiGet(uri, (err, body) => {
      !isGenerate && store.doSetLoadingMessage('Loading...');
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: body.data });
        dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: body.data });
        dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: requestStatus } });
        dispatch({ type: 'UPDATE_PRE_APP', payload: body.data.request.preApplications.filter(item => item.preAppID === preAppID)[0] });
        if (isGenerate) {
          store.doGenerateDraftDocuments();
        }
        else {
          store.doUpdateSelectedRequest('5');
          store.doUpdateRelativeUrl(`/forms/${requestID}`);
          store.doFetchUserFilesDetails(preAppID)
            .finally(() => {
              dispatch({ type: 'SET_LOADING_STATE', payload: false });
            });
        }
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while retrieving the request, please try again', status: body?.status ?? err?.status ?? 'Request Retrieval Error' });
        dispatch({ type: 'SET_LOADING_STATE', payload: false });
      }
    });
  },
  doAddPreApp: (data) => ({ apiPost, dispatch, store }) => {
    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Creating Pre-application...');

    const uri = '/api/PreApplication/addPreApplication';

    return apiPost(uri, data, (err, body) => {
      store.doSetLoadingState(false);
      store.doSetLoadingMessage('Loading...');
      if (!err && body.status === 'Success') {
        dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: { projectID: body.data.projectID, requestID: body.data.requestID } });
        dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: { projectID: body.data.projectID, requestID: body.data.requestID } });
        dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
        dispatch({ type: 'UPDATE_PRE_APP', payload: { preAppID: body.data.preAppID } });
        store.doUpdateRelativeUrl('/forms');
        store.doFetchUserFilesDetails(body.data.preAppID);
        store.doModalClose();
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while creating the request, please try again', status: body?.status ?? err?.status ?? 'Request Creation Error' });
      }
    });
  },
  doUploadAndUpdatePreApp: (data, status) => ({ store }) => {
    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Uploading Pre-application Request Files...');

    if (store.selectSelectedFiles()?.length > 0) {
      // Format to FormData
      const formdata = new FormData();
      formdata.append('overwrite', false);
      formdata.append('isAnonymous', false);
      store.selectSelectedFiles().forEach(item => {
        const fileMetaData = {
          ProjectID: store.selectProjectID(),
          RequestID: store.selectRequestID(),
          Version: 0,
          Type: status,
          Tags: item?.tags
        };
        formdata.append('files', item?.file);
        formdata.append('fileMetaData', JSON.stringify(fileMetaData));
      });

      Promise.all([store.doUploadFiles(formdata)])
        .then(results => {
          if (results?.[0]?.status === 'Success') {
            // if all files upload successfully
            store.doSetLoadingMessage('Saving Pre-application Request...');
            store.doUpdatePreApp(data);
            store.doResetUsersFileData();
            store.doFetchUserFilesDetails(store.selectPreAppID());
          } else {
            // if any of the files failed to upload
            store.doSetLoadingState(false);
            store.doSetLoadingMessage('Loading...');
            store.doResetUsersFileData();
            store.doSecondaryModalOpen(statusModal, {
              status: 'ERROR! Failed Uploads',
              msg: (
                <>
                  <p>{results?.[0]?.msg}</p>
                  <p>Retry saving/submitting your Pre-application Request in a few minutes.</p>
                </>),
              saveText: 'Return to request',
            });
          }
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      // If no files were selected, just update PreApp
      store.doUpdatePreApp(data);
    }
  },
  doUpdatePreApp: (data) => ({ apiPut, dispatch, store }) => {
    store.doSetLoadingState(true);
    store.doSetLoadingMessage('Saving Pre-application Request...');

    const uri = '/api/PreApplication/updatePreApplication';

    return apiPut(uri, data, (err, body) => {
      const signed = Boolean(data.request.isSubmit);
      store.doSetLoadingState(false);
      store.doSetLoadingMessage('Loading...');
      if (!err && body.status === 'Success') {
        signed && toast.success('Pre-application has been successfully submitted!');
        signed && store.doModalOpen(submissionModal);
        signed && store.doUpdateRelativeUrl('/dashboard');
        !signed && toast.success('Pre-application has been successfully saved!');
        !signed && dispatch({ type: 'UPDATE_REQUEST_API_DATA', payload: body.data });
        !signed && dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: body.data });
        !signed && dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { requestStatus: 'Draft' } });
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An error occurred while saving or submitting the request, please try again', status: body?.status ?? err?.status ?? 'Request Save/Submission Error' });
        dispatch({ type: 'UPDATE_PRE_APP_ERRORS', payload: body?.data });
      }
    });
  },
  doUpdatePreAppRequest: (data) => ({ dispatch, store }) => {
    dispatch({ type: 'UPDATE_PRE_APP', payload: data });
    dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: { preApplications: [store.selectPreAppData()] } });
  },
  doResetPreAppRequest: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_PRE_APP' });
  },
};

export default preAppFormDataBundle;