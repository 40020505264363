import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Radio } from '@trussworks/react-uswds';

import '@styles/index.scss';

const RadioInput = ({
  className,
  id,
  label,
  name,
  onChange = () => {},
  onBlur = () => {},
  value,
  ...customProps
}) => {

  const { register } = useFormContext();

  const fieldRegister = register(name, {onBlur:onBlur, onChange:onChange});

  return (
    <div className='margin-bottom-1'>
      <Radio
        className={className}
        inputRef={fieldRegister.ref}
        id={id ?? value}
        label={label}
        name={name} 
        onBlur={fieldRegister.onBlur}
        onChange={fieldRegister.onChange}
        value={value}
        {...customProps}
      />
    </div>
  );
};

export default RadioInput;