import { useEffect, useRef } from 'react';

const useWindowListener = (event, callback, options = {}) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (e) => callbackRef.current(e);
    window.addEventListener(event, handler, options);
    return () => window.removeEventListener(event, handler, options);
  }, [event, options]);
};

export default useWindowListener;
