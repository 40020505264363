import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import './HeaderCell.scss';
import { Button } from '@trussworks/react-uswds';
import Icon from '@mdi/react';
import { mdiCloseOctagon, mdiPlusBox } from '@mdi/js';
import TableDragInput from '../tableDragInput';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { tError, tSuccess } from '@src/utils/toast-helpers';
import statusModal from '@forms/components/modals/statusModal';
import { validateGeometryInValidDistrict } from '@src/utils/helpers';


const HeaderCell = connect(
  'doGetGeometryData',
  'doSecondaryModalOpen',
  ({
    doGetGeometryData,
    doSecondaryModalOpen,
    table,
    addFeaturesFromRows,
  }) => {
    const meta = table.options.meta;
    const selectedRows = table.getSelectedRowModel().rows;
    const [selectAll, setSelectAll] = useState(false);
    const [fileNames, setFileNames] = useState();

    const columns = useMemo(() => table.getAllColumns(), [table]);

    const toggleSelectAll = () => {
      setSelectAll(!selectAll);
      table.toggleAllRowsSelected(!selectAll);
    };

    const removeRows = () => {
      meta.removeSelectedRows(
        table.getSelectedRowModel().rows.map((row) => row.index)
      );
      table.resetRowSelection();
    };

    const handleGDBFileUpload = useCallback((files) => {
      const toastID = toast.loading('Processing file...');
      const getGeometryData = async (file) => {
        await doGetGeometryData(file, false, toastID);
      };
      getGeometryData(files[0]);
    }, [doGetGeometryData]);

    const handleFileLoaded = useCallback((files) => {
      const fileExtensions = files.map(file => file.name.split('.')[1]);
      if (fileExtensions.includes('zip') || fileExtensions.includes('gdb')) {
        files.length < 2 ? handleGDBFileUpload(files) : alert('Only one GDB file can be uploaded at a time!');
      }
      else {
        files && setFileNames(files);
      }
    }, [handleGDBFileUpload]);

    const cleanValue = (value) => value?.trim().replace(/°/g, '');
    const applyToHeaders = useCallback( async (rowData) => {
      const columnHeaders = columns.map(column => column.columnDef?.header);
      const providedHeaders = [...new Set(rowData.flatMap(data => Object.keys(data)))];
      const headersMatch = providedHeaders?.every(header => columnHeaders.includes(header.trim()));
      const numbersValid = rowData?.map(feature => {
        const latitude = cleanValue(feature?.Latitude);
        const longitude = cleanValue(feature?.Longitude);
        const measurementAmount = cleanValue(feature?.['Measurement Amount']);
        const isValidCoordinate = !isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude)) && !isNaN(parseFloat(measurementAmount));
        return isValidCoordinate;
      });
      const isValidNumbers = !numbersValid?.includes(false);
      if (!headersMatch) {
        handleInvalidHeadersAlert(doSecondaryModalOpen, statusModal);
      } else {
        if (!isValidNumbers) {
          handleInvalidCoordinateAlert(doSecondaryModalOpen, statusModal);
        }
        rowData.forEach(dataItem => {
          columns.forEach(column => {
            if (dataItem?.hasOwnProperty(column.columnDef?.header)) {
              let columnValue = cleanValue(dataItem[column.columnDef?.header]);
              // Map to column.id
              if (column.columnDef?.header && dataItem?.hasOwnProperty(column.columnDef?.header)) {
                if (['Latitude', 'Longitude', 'Measurement Amount'].includes(column.columnDef?.header)) {
                  if (!isNaN(columnValue)) {
                    dataItem[column.columnDef?.header] = columnValue;
                  } else {
                    dataItem[column.columnDef?.header] = '';
                  }
                } else {
                  dataItem[column.columnDef?.header] = columnValue;
                }
              }
            }
          });
        });

        const generateMultiPointGeoJson = (data) => ({
          type: 'MultiPoint',
          coordinates: data?.map(item => [parseFloat(item.Longitude), parseFloat(item.Latitude)])
        });

        const multiPointGeometry = generateMultiPointGeoJson(rowData);

        const isValidDistrict = await validateGeometryInValidDistrict(multiPointGeometry);

        if(isValidDistrict)
        {
          addFeaturesFromRows(rowData);
        }
        else
        {
          doSecondaryModalOpen(statusModal,{msg: 'A USACE regulatory district could not be determined for all CSV coordinates, Please verify all coordinates within the csv and try again.', status: 'CSV contains invalid coordinates'});
        }


      }
    }, [columns, addFeaturesFromRows, doSecondaryModalOpen]);

    const handleInvalidCoordinateAlert = (doSecondaryModalOpen, statusModal) => {
      doSecondaryModalOpen(statusModal, { msg: 'The file contained invalid values. Please enter the values manually for the highlighted fields.' });
    };

    const handleInvalidHeadersAlert = (doSecondaryModalOpen, statusModal) => {
      doSecondaryModalOpen(statusModal, { msg: 'The file contained invalid header values. Please verify the headers of the CSV file and re-upload' });
    };

    const handleFiles = useCallback(async () => {
      if (!fileNames || fileNames?.length === 0) {
        return;
      }
      const toastId = toast.loading('Processing file(s)...');
      try {
        const allRowData = [];
        for (const file of fileNames) {
          const fileContents = await readFile(file);
          const workbook = XLSX.read(fileContents, { type: 'buffer' });
          workbook.SheetNames.forEach(sheetName => {
            const sheet = workbook.Sheets[sheetName];
            for (const cell in sheet) {
              if (cell[0] === '!') continue;
              if (sheet?.[cell]?.v !== undefined && sheet?.[cell]?.v !== null) {
                sheet[cell].v = cleanValue(String(sheet[cell].v));
                sheet[cell].t = 's';
              }
            }
            const rowObject = XLSX.utils.sheet_to_json(sheet);
            allRowData.push({ sheetName, rowObject });
          });
          const flattenedData = allRowData.flatMap(entry => entry.rowObject);
          applyToHeaders(flattenedData, columns, 
            () => handleInvalidCoordinateAlert(doSecondaryModalOpen, statusModal), 
            () => handleInvalidHeadersAlert(doSecondaryModalOpen, statusModal), 
            addFeaturesFromRows);
        }
        tSuccess(toastId, 'File(s) processed successfully.');
      } catch (error) {
        console.error(error);
        tError(toastId, 'An error occurred while processing file(s).');
      }
    }, [fileNames, applyToHeaders, columns, addFeaturesFromRows, doSecondaryModalOpen]);
    
    useEffect(() => {
      handleFiles();
    }, [handleFiles]);

    const readFile = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e.target.error);
      reader.readAsArrayBuffer(file);
    });

    return (
      <div className='header-buttons'>
        <input
          type='checkbox'
          title='Select All'
          checked={selectAll}
          onChange={toggleSelectAll}
        />
        {selectedRows.length > 0 ? (
          <Button
            className={'remove-button'}
            title='Remove Selected Rows'
            size='small'
            onClick={removeRows}>
            <Icon path={mdiCloseOctagon} size={'16px'} />
        Remove Selected Rows
          </Button> 
        ) : null}
        <Button
          className='add-button'
          title='Add New Row'
          size='small'
          onClick={meta?.addRow}
        >
          <Icon className='mr-1' path={mdiPlusBox} size={'16px'} />
          Add New Row
        </Button> 
        <TableDragInput onChange={handleFileLoaded} />
      </div>
    );
  });

export default HeaderCell;