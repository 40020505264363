import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import { AgGridReact } from 'ag-grid-react';
import { Alert } from '@trussworks/react-uswds';
import { mdiAccountPlus, mdiCloseOctagon } from '@mdi/js';
import Card from '@components/card';
import FieldHeader from '@forms/components/Form/FieldHeader';
import { Button } from '@trussworks/react-uswds';
import Icon from '@mdi/react';
import AddPropertyOwnerModal from '@forms/components/modals/AddPropertyOwnerModal';
import SelectInput from '@components/new-inputs/selectInput';
import TextAreaInput from '@components/new-inputs/textAreaInput';
import EditCellRenderer from '@forms/components/gridCellRenderers/editCellRender';
import ValidationCard from '@forms/components/Form/ValidationCard';
import DragInput from '@components/drag-input/dragInput';
import LinkButton from '@components/link/linkButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { ContactTypes, ContactsFormNames, FileTypes, TemplateFiles } from '@src/utils/enums';
import useErrorFocus from '@src/customHooks/useErrorFocus';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ErrorSummary from '@components/error-summary/ErrorSummary';

export const JDFormPropertyOwnersMetadata = {
  sectionName: 'Property Owners',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const JDFormPropertyOwners = connect(
  'doDownloadFile',
  'doDownloadPopulatedFile',
  'doDeleteFile',
  'doClearContactType',
  'doModalOpen',
  'doResetFileSection',
  'doUpdateSectionValidity',
  'doUpdateSelectedFiles',
  'doUpdateRequestObject',
  'doUpdateJDRequest',
  'doSaveTable',
  'selectRightOfEntryFile',
  'selectRequestAPIData',
  'selectSteps',
  'selectActiveStep',
  'selectTotalFileSize',
  'selectMaxTotalFileSize',
  'selectMaxFileCount',
  'selectSelectedFiles',
  ({
    doDownloadFile,
    doDownloadPopulatedFile,
    doDeleteFile,
    doClearContactType,
    doModalOpen,
    doResetFileSection,
    doUpdateSectionValidity,
    doUpdateSelectedFiles,
    doUpdateRequestObject,
    doUpdateJDRequest,
    doSaveTable,
    rightOfEntryFile,
    requestAPIData,
    steps,
    activeStep,
    totalFileSize,
    maxTotalFileSize,
    maxFileCount,
    selectedFiles,
    isReadOnly,
    stepNo,
    componentID
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const [rowData, setRowData] = useState([]);
    const [ROEFile, setROEFile] = useState([]);
    const [isMultiple, setIsMultiple] = useState(false);
    const [isOverTotalFileLimit, setIsOverTotalFileLimit] = useState(false);
    const [isOverFileCountLimit, setIsOverFileCountLimit] = useState(false);
    const propertyOwnerTableLength = rowData?.length;

    const schema = (propertyOwnerTableLength) => yup.object().shape({
      hasAddPropertyOwners: yup.string().required('Please select an option'),
      propertyOwnersTable: yup.boolean().when('hasAddPropertyOwners', { is: (val) => ((val === 'true' || val === true) && propertyOwnerTableLength < 1), then: () => yup.boolean().required('Property Owners: At least one property owner is required') }),
      ownerEntryComments: yup.string().nullable(),
    });

    const defaultValues = {
      hasAddPropertyOwners: requestAPIData?.request?.jdRequests?.[0]?.hasAddPropertyOwners !== null ? requestAPIData?.request?.jdRequests?.[0]?.hasAddPropertyOwners : '',
      ownerEntryComments: requestAPIData?.request?.ownerEntryComments ?? '',
    };

    const methods = useForm({ resolver: yupResolver(schema(propertyOwnerTableLength)), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors }, watch, setError, clearErrors, trigger } = methods;
    const errorCount = Object.entries(errors)?.length;
    const hasAddPropertyOwners = watch('hasAddPropertyOwners');
    const ownerEntryComments = watch('ownerEntryComments');

    const columnDefs = [
      { field: 'actions', headerName: 'Actions', width: 65, cellRenderer: 'editRowRenderer', cellRendererParams: { doModalOpen: doModalOpen, modalComponent: AddPropertyOwnerModal, isEdit: true, setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly } },
      { field: 'firstName', headerName: 'First Name', flex: 1, resizable: true },
      { field: 'middleName', headerName: 'Middle Name', flex: 1, resizable: true },
      { field: 'lastName', headerName: 'Last Name', flex: 1, resizable: true },
      { field: 'address', headerName: 'Address 1', flex: 1, resizable: true },
      { field: 'city', flex: 1, resizable: true },
      { field: 'state', flex: 1, maxWidth: 70 },
      { field: 'country', flex: 1, maxWidth: 70 },
    ];

    const clearRowData = useCallback(() => {
      setRowData([]);
      doClearContactType(ContactTypes.PropertyOwner);
    }, [doClearContactType]);

    useEffect(() => {
      rowData && doSaveTable('propOwners', rowData);
    }, [rowData, doSaveTable]);

    useEffect(() => {
      // File validations
      setIsMultiple(ROEFile?.length > 1 ? true : false);
      setIsOverTotalFileLimit(((isNaN(totalFileSize) ? 0 : totalFileSize) + ROEFile?.[0]?.size > maxTotalFileSize) ? true : false);
      setIsOverFileCountLimit((selectedFiles?.length + 1 > maxFileCount) ? true : false);
    }, [ROEFile, maxFileCount, maxTotalFileSize, totalFileSize, selectedFiles?.length]);

    useEffect(() => {
      const doSelectFile = () => {
        const RoEFile = {
          file: ROEFile?.[0],
          section: ContactsFormNames.RightOfEntry,
          fileName: ROEFile?.[0]?.name,
          componentID: componentID
        };
        doUpdateSelectedFiles(RoEFile);
      };
      // Set selected file if valid
      doResetFileSection(ContactsFormNames.RightOfEntry);
      (ROEFile?.length > 0 && !isMultiple && !isOverTotalFileLimit && !isOverFileCountLimit) && doSelectFile();
    }, [ROEFile, isMultiple, isOverTotalFileLimit, isOverFileCountLimit, doResetFileSection, componentID, doUpdateSelectedFiles]);

    useEffect(() => {
      // Load tables from database
      let propertyowners = requestAPIData?.request?.contacts?.filter((contact) => contact.contactType === ContactTypes.PropertyOwner);
      // If country is null, default to US
      propertyowners = propertyowners?.map(contact => contact.country === null ? { ...contact, country: 'US' } : contact);
      propertyowners && setRowData(propertyowners);
    }, [requestAPIData]);

    useEffect(() => {
      if (rowData?.length > 0) {
        (hasAddPropertyOwners === 'false' || hasAddPropertyOwners === false) && clearRowData();
      }
    }, [rowData, hasAddPropertyOwners, clearRowData]);

    useEffect(() => {
      doUpdateRequestObject({ ownerEntryComments: ownerEntryComments });
    }, [ownerEntryComments, doUpdateRequestObject]);

    useEffect(() => {
      if ((hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) && rowData?.length < 1) {
        setError('propertyOwnersTable', { type: 'custom', message: 'Property Owners: At least one property owner is required' });
      }
      else {
        clearErrors('propertyOwnersTable');
      }

    }, [hasAddPropertyOwners, rowData?.length, setError, clearErrors]);


    useEffect(() => {
      const valid = ((hasAddPropertyOwners === 'false' || hasAddPropertyOwners === false) ? true : ((hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) && (rowData?.length > 0)));
      const validity = errorCount === 0 && valid;
      doUpdateSectionValidity(JDFormPropertyOwnersMetadata.sectionName, validity, stepNo, isReadOnly);
    }, [errorCount, rowData, hasAddPropertyOwners, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      doUpdateJDRequest({ hasAddPropertyOwners: hasAddPropertyOwners });
    }, [hasAddPropertyOwners, doUpdateJDRequest]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <FieldHeader text='Property Owner(s)' subtext='If USACE determines a site visit is needed, you must include contact information for all current deeded property owners and provide a signed right-of-entry for each owner. A right-of-entry form allows USACE to access the project area when conducting a site investigation or jurisdictional determination. This form ensures that USACE has the legal right to enter the property.'><p>
          <LinkButton onClick={() => doDownloadFile(TemplateFiles.RightOfEntry, FileTypes.Template)} title='Download a blank Right of Entry form' content='Download a blank Right of Entry form' /> to complete or <LinkButton onClick={() => doDownloadPopulatedFile(ContactsFormNames.RightOfEntry, TemplateFiles.RightOfEntry)} title='download a Right of Entry form populated' content='download a Right of Entry form populated' /> with the information you entered. and upload the completed document in the section below.</p></FieldHeader>
        <label className='h6 border-bottom w-100 pb-2 mt-3' htmlFor='rightOfEntry'>Right of Entry</label>
        <Alert type='info'>
          <p>The property owner or an easement holder must provide right-of-entry to the U.S. Army Corps of Engineers and be a duly authorized owner of record of the property.</p><p> If the agent signs the right-of-entry, they must have an agent authorization signed by the property owner.</p>
          <p>For multiple property owners, please consolidate right-of-entry forms into one file before uploading. Alternatively, you can upload additional right-of-entry forms later in the Supporting Documentation section.</p>
          <b>Individual files cannot exceed 100MB, and in total cannot exceed 500MB per save.</b>
        </Alert>
        <div className='row mt-1'>
          <div className='col'>
            <DragInput
              name='rightOfEntry'
              text='Click to browse or drag and drop your Right-Of-Entry Form(s) here.'
              accept={{ 'application/pdf': ['.pdf'], 'application/docx': ['.docx'] }}
              onChange={setROEFile}
              isDisabled={rightOfEntryFile?.[0]?.key || isReadOnly}
              value={rightOfEntryFile}
              doDownloadFile={() => doDownloadFile(rightOfEntryFile?.[0]?.fileName, FileTypes.Request)}
              doDeleteFile={() => doDeleteFile(rightOfEntryFile?.[0]?.fileName, componentID)}
              isReadOnly={isReadOnly}
            />
            {isMultiple && <ValidationCard message='Multiple files were selected. Please select only 1 file' />}
            {isOverTotalFileLimit && <ValidationCard message='Only 500MB of files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_size_error' />}
            {isOverFileCountLimit && <ValidationCard message='Only 50 files can be uploaded per save. Please hit "Save Progress" to upload your files and try again.' id='file_count_error' />}
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <TextAreaInput name='ownerEntryComments' label='Right of Entry Comment' readOnly={isReadOnly} />
          </div>
        </div>

        <div className='ml-2' id='propertyOwnersTable'>
          <SelectInput name='hasAddPropertyOwners' label='Are there any property owners other than the applicant?' required className='w-50 mb-3' readOnly={isReadOnly}>
            <option key='1' value='true'>Yes</option>
            <option key='2' value='false'>No</option>
          </SelectInput>
        </div>

        {(hasAddPropertyOwners === 'true' || hasAddPropertyOwners === true) &&
          <>
            <Card className='mb-3'>
              <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
                <div className='d-flex justify-content-center col-4'>
                  <Button
                    className='add-property-owner-button'
                    title='Add a Property Owner'
                    size='small'
                    onClick={() => doModalOpen(AddPropertyOwnerModal, { setRowData: setRowData, rowData: rowData, isReadOnly: isReadOnly })}
                    disabled={isReadOnly}
                  >
                    <Icon className='mr-1' path={mdiAccountPlus} size={'16px'} />
                    Add a Property Owner
                  </Button> 
                </div>
                <div className='d-flex justify-content-center col-4'>
                </div>
                <div className='d-flex justify-content-center col-4'>
                  <Button
                    className={`clear-table-button ${(rowData.length === 0 || isReadOnly) ? 'disabled' : 'hover'}`}
                    title='Clear Property Owner(s)'
                    size='small'
                    onClick={() => { setRowData([]); doClearContactType(ContactTypes.PropertyOwner); }}
                    disabled={isReadOnly || rowData.length < 1}
                  >
                    <Icon path={mdiCloseOctagon} size={'16px'} />
                    Clear Property Owner(s)
                  </Button> 
                </div>
              </div>
              <div className='ag-theme-balham' style={{ height: 400 }}>
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  pagination={true}
                  paginationAutoPageSize={true}
                  rowHeight={35}
                  gridOptions={{
                    alwaysShowVerticalScroll: true
                  }}
                  components={{
                    'editRowRenderer': EditCellRenderer,
                  }}
                  suppressClickEdit
                />
              </div>
            </Card>
          </>
        }
      </FormProvider>
    );
  }
);

JDFormPropertyOwners.metadata = JDFormPropertyOwnersMetadata;

export default JDFormPropertyOwners;
