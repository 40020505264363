import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';

import Tooltip from '../tooltip/tooltip';
import genericSecondaryModal from '../../app-pages/Forms/components/modals/genericSecondaryModal';

import { sanitizeASCII, isValidASCII, isDateValid } from '../../utils/helpers';
import { decimalNumberRegex } from '../../utils/regex';
import { ErrorMessages } from '@src/utils/enums';

import './inputs.scss';
import '../../styles/index.scss';

const InputField = connect('doSecondaryModalOpen', ({
  doSecondaryModalOpen,
  className = 'w-100',
  name,
  label,
  type = 'text',
  valueAsNumber = false,
  valueAsDate = false,
  maxLength = type === 'text' ? 256 : null,
  required,
  onChange = () => { },
  onBlur = () => { },
  validations,
  pattern,
  tooltip,
  tooltipClickable,
  placeholder,
  inputMode,
  readOnly,
  signature,
  uppercase,
  min,
  max,
  ...customProps
}) => {
  // Setting the End Date
  var endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 100);
  endDate = new Date(endDate).toISOString().slice(0, 10);

  const { register, watch, setValue, formState: { errors } } = useFormContext();
  const inputError = errors[name];
  const input = watch(name);

  useEffect(() => {
    if (input && typeof input === 'string') {
      if (isValidASCII(input)) {
        setValue(name, input);
      } else {
        // Optionally handle invalid input here, such as warning the user
        const msg = ErrorMessages.InvalidCharacters;
        doSecondaryModalOpen(genericSecondaryModal, { title: 'Invalid Characters', msg: msg });
        setValue(name, sanitizeASCII(input));
      }
    };
  }, [input, name, setValue, doSecondaryModalOpen]);

  const handleBlur = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    onBlur(e);
    // Input type validations
    if (type === 'date') {
      isDateValid(value, min, endDate) === false && setValue(name, '');
    }
    if (type === 'number' || valueAsNumber) {
      if (decimalNumberRegex.test(e?.target?.value) !== true) {
        // Parse out any letters and extra periods from int/float value
        const parsedLettersVal = e?.target?.value?.replace(/[^0-9.]/g, '');
        const rx = /^(\d*\.)([\d.]*)$/;
        const parsedPeriodsVal = parsedLettersVal?.replace(rx, (a, b, c) => b + c?.replace(/\./g, ''));
        setValue(name, parsedPeriodsVal);
      }
    }
  };

  const setMax = () => max ? max : endDate;

  return (
    <>
      <label
        className={'mr-2 mb-0 mt-2 w-100'}
        htmlFor={name}>
        {label}{required ? <span className='asterisk-color'>*</span> : <span style={{ fontStyle: 'italic' }}> (optional)</span>}
        {tooltip && <>
          <Tooltip name={name} header={label} content={tooltip} clickable={tooltipClickable} iconStyle={{ marginLeft: '5px' }} />
        </>}
      </label>
      <input
        type={type}
        className={`form-control mt-1 ${className} ${inputError ? ' is-invalid' : ''} ${uppercase ? 'uppercase' : ''}`}
        id={name}
        name={name}
        placeholder={placeholder}
        pattern={pattern}
        inputMode={inputMode}
        readOnly={readOnly}
        required={required}
        maxLength={maxLength}
        {...register(name, { valueAsNumber, valueAsDate, onChange, onBlur: handleBlur, pattern, ...validations })}
        aria-invalid={inputError ? 'true' : 'false'}
        aria-required={required}
        min={type === 'date' ? min : null}
        max={type === 'date' ? setMax() : null}
        {...customProps} />
      {signature && <div className='mb-2'><i>{signature}</i></div>}
    </>
  );
});

export default InputField;
