import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'redux-bundler-react';

import Icon from '@mdi/react';
import { mdiAlertCircle, mdiHelpCircle, mdiClose } from '@mdi/js';

import './tooltip.scss';
import tooltipModal from './tooltipModal';


const Tooltip = connect(
  'doSecondaryModalOpen',
  ({
    doSecondaryModalOpen,
    header,
    content,
    iconSize = 'medium',
    isError,
  }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const tooltipRef = useRef(null);
    // function to get icon size
    const getIconSize = iconSize => {
      switch (iconSize) {
        case 'small':
          return '15px';
        case 'medium':
          return '18px';
        case 'large':
          return '20px';
        case 'x-large':
          return '28px';
        default:
          return '18px';
      }
    };

    const getContentLength = (content) => {
      if (typeof content === 'string') {
        return content.length;
      } else {
        let length = 0;
        React.Children.forEach(content, child => {
          if (typeof child === 'string') {
            length += child.length;
          } else if (React.isValidElement(child) && child.props.children) {
            length += getContentLength(child.props.children);
          }
        });
        return length;
      }
    };


    const handleIconClick = (e) => {
      const contentLength = getContentLength(content);
      e.stopPropagation();
      e.preventDefault();
      if (contentLength > 1) {
        doSecondaryModalOpen(tooltipModal, { msg: content, title: header, isError: isError, size: contentLength < 256 ? 'sm' : 'md' });
      }
      else {
        setIsTooltipOpen(true);
      }
    };

    const handleCloseButtonClick = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsTooltipOpen(false);
    };

    const handleOutsideClick = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsTooltipOpen(false);
      }
    };

    useEffect(() => {
      if (isTooltipOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
      } else {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [isTooltipOpen]);

    const handleKeyDown = (e) => {
      e.key === 'Enter' && setIsTooltipOpen(true);
    };

    const handleClose = (e) => {
      e.key === 'Enter' && setIsTooltipOpen(false);
    };


    return (
      <>
        <div className='custom-tooltip-container'>
          <Icon path={isError ? mdiAlertCircle : mdiHelpCircle} color={isError ? '#D43929' : '#005ea2'} size={getIconSize(iconSize)} className='help-icon' onClick={handleIconClick} onKeyDown={(e) => handleKeyDown(e)} tabIndex={0} />
          {isTooltipOpen && (
            <div ref={tooltipRef} className='custom-tooltip-card'>
              <div className='custom-tooltip-arrow'></div>
              <div className='custom-tooltip-content'>
                <div className='close-icon-container'>
                  <Icon
                    tabIndex={0}
                    path={mdiClose}
                    color={'#005ea2'}
                    size={1}
                    onClick={handleCloseButtonClick}
                    onKeyDown={(e) => handleClose(e)}
                    className='close-icon'
                  />
                </div>
                <div className='custom-tooltip-inner'>{content}</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default Tooltip;
