import { useEffect, useState, useCallback } from 'react';
import { connect } from 'redux-bundler-react';

import { ModalContent, ModalFooter } from '@components/modal';
import AreasOfInterestSelect from './_shared/AreasOfInterest';
import AddressValidator from '../Form/AddressValidator';
import { salutations, states, countries } from '@pages/Forms/input-forms/_helper';

import SelectInput from '@components/new-inputs/selectInput';
import InputField from '@components/new-inputs/inputField';
import CheckboxInput from '@components/new-inputs/checkboxInput';
import MultiSelectInput from '@components/new-inputs/multiSelectInput';
import Tooltip from '@components/tooltip/tooltip';
import { toast } from 'react-toastify';

import { districtsArr, divisions } from './_shared/helper';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { emailRegex, phoneRegex, usPhoneRegex, countryCodeRegex, cityRegex, zipCodeRegex } from '@src/utils/regex';
import { formatUSPhoneNumber, mapCountryAlpha3toAlpha2 } from '@src/utils/helpers';
import PhoneInputField from '@components/new-inputs/phoneInputField';
import countryRegionData from 'country-region-data/dist/data-umd';
import { Alert } from '@trussworks/react-uswds';
import LinkButton from '@components/link/linkButton';
import AddressSuggestInput from '@components/new-inputs/addressSuggestInput';
import { ProfileRoles } from '@src/utils/enums';
import ErrorSummary from '@components/error-summary/ErrorSummary';

const rolesHelperContent = (
  <>
    Role Descriptions:
    <ol>
      <li><p><span className='text-bold'>Applicant</span> – Entity with desire to undertake the proposed activity that possesses or will possess the requisite property interest.</p></li>
      <li><p><span className='text-bold'>Agent</span> - Entity acting on behalf of the applicant.</p></li>
      <li><p><span className='text-bold'>External Agency</span> – Future Capability</p></li>
      <li><p><span className='text-bold'>USACE Regulatory User</span> – Internal USACE Project Managers and RRS Administrators.</p></li>
    </ol>
  </>
);

const schema = yup.object().shape({
  role: yup.string().required('Please select an option').test('isExternalAgency', 'Please select an option', val => val !== ProfileRoles.ExternalAgency),
  salutation: yup.string().required('Field is required'),
  firstName: yup.string().required('Field is required'),
  middleName: yup.string().nullable(),
  lastName: yup.string().required('Field is required'),
  companyName: yup.string().nullable(),
  agencyName: yup.string().when('role', {
    is: ProfileRoles.ExternalAgency,
    then: () => yup.string().required('Field is required'),
    otherwise: () => yup.string().nullable()
  }),
  agencyType: yup.array().when('role', {
    is: ProfileRoles.ExternalAgency,
    then: () => yup.array().min(1, 'Please select an option').required('Please select an option')
  }),
  phoneCountryCode: yup.string().when(['role'], {
    is: (role) => role !== ProfileRoles.USACERegulator,
    then: () => yup.string().required('Field is required').matches(countryCodeRegex, 'Field is invalid'),
    otherwise: () => yup.string().nullable()
  }),
  phone: yup.string().when(['phoneCountryCode', 'role'], {
    is: (countryCode, role) => countryCode === '1' && role !== ProfileRoles.USACERegulator,
    then: () => yup.string().required('Field is required').matches(usPhoneRegex, 'Field is invalid'),
    otherwise: () => yup.string().when(['phoneCountryCode', 'role'], {
      is: (countryCode, role) => countryCode !== '1' && role !== ProfileRoles.USACERegulator,
      then: () => yup.string().required('Field is required').matches(phoneRegex, 'Field is invalid')
    }).nullable()
  }),
  employeeType: yup.string().when('role', {
    is: ProfileRoles.USACERegulator,
    then: () => yup.string().required('Please select an option'),
    otherwise: () => yup.string().nullable()
  }),
  emailAddress: yup.string().required('Field is required').matches(emailRegex, 'Field is invalid'),
  address: yup.string().when('role', {
    is: (val) => val !== ProfileRoles.USACERegulator,
    then: () => yup.string().required('Field is required'),
    otherwise: () => yup.string().nullable()
  }),
  addressTwo: yup.string().nullable(),
  city: yup.string().when('role', {
    is: (val) => val !== ProfileRoles.USACERegulator,
    then: () => yup.string().matches(cityRegex, 'Field is invalid').required('Field is required'),
    otherwise: () => yup.string().nullable()
  }),
  country: yup.string().required('Country is required'),
  state: yup.string().when(['role', 'country'], {
    is: (val, country) => val !== ProfileRoles.USACERegulator && country === 'US',
    then: () => yup.string().required('Please select an option'),
    otherwise: () => yup.string().nullable()
  }),
  zipcode: yup.string().when(['role', 'country'], {
    is: (val, country) => val !== ProfileRoles.USACERegulator && country === 'US',
    then: () => yup.string().required('Field is required').matches(zipCodeRegex, 'Field is invalid'),
    otherwise: () => yup.string().nullable()
  }),
  usaceHQ: yup.boolean().nullable(),
  division: yup.array().when('role', {
    is: (val) => val === ProfileRoles.USACERegulator || val === ProfileRoles.ExternalAgency,
    then: () => yup.array().when('usaceHQ', {
      is: (val) => val === false || val === null,
      then: () => yup.array()
    })
  }),
  district: yup.array().when('role', {
    is: (val) => val === ProfileRoles.USACERegulator || val === ProfileRoles.ExternalAgency,
    then: () => yup.array().when('usaceHQ', {
      is: (val) => val === false || val === null,
      then: () => yup.array()
    })
  })
});

const ProfileModal = connect(
  'doSaveUserProfile',
  'doUpdateUserProfile',
  'doResetAddressCandidatesResults',
  'selectAuthData',
  'selectUserProfileData',
  'selectAddressCandidateResults',
  'selectIsUserRoleValid',
  ({
    doSaveUserProfile,
    doUpdateUserProfile,
    doResetAddressCandidatesResults,
    authData,
    userProfileData,
    addressCandidateResults,
    isUserRoleValid,
    edit,
  }) => {
    const getDivisions = () => {
      if (userProfileData?.areaOfInterest) {
        const divisionlist = userProfileData.areaOfInterest.map((val) => {
          if (val <= 10) {
            const matchedDivision = divisions.filter(division => division.value === val)[0];
            return { value: val, label: matchedDivision ? matchedDivision.label : null };
          }
          return null;
        }).filter(Boolean);
        return divisionlist;
      };
      return [];
    };

    const getDistricts = () => {
      if (userProfileData?.areaOfInterest) {
        const districtlist = userProfileData.areaOfInterest.map((val) => {
          if (val > 10) {
            const matchedDistrict = districtsArr.filter(district => district.value === val)[0];
            return { value: val, label: matchedDistrict ? matchedDistrict.label : null };
          }
          return null;
        }).filter(Boolean);
        return districtlist;
      };
      return [];
    };

    const getAgencyType = () => {
      if (userProfileData?.agencyType) {
        const agencyTypes = userProfileData.agencyType.map((val) => ({ value: val, label: val }));
        return agencyTypes;
      }
      return [];
    };

    const getUserEmail = () => userProfileData?.emailAddress ? userProfileData.emailAddress : authData.email;
    const roleOptions = email => /@usace.army.mil\s*$/.test(email) ? true : false;

    const defaultValues = {
      role: userProfileData?.role ? (userProfileData?.role === ProfileRoles.ExternalAgency ? null : userProfileData?.role) : (roleOptions(getUserEmail()) === true ? ProfileRoles.USACERegulator : null),
      salutation: userProfileData?.salutation ?? null,
      firstName: userProfileData?.firstName ? userProfileData.firstName : authData.given_name,
      middleName: userProfileData?.middleName ?? null,
      lastName: userProfileData?.lastName ? userProfileData.lastName : authData.family_name,
      companyName: userProfileData?.companyName ?? null,
      agencyName: userProfileData?.agencyName ?? null,
      phone: userProfileData?.phone ?
        (userProfileData.phoneCountryCode === '1' ? formatUSPhoneNumber(userProfileData.phone) : userProfileData.phone)
        : null,
      phoneCountryCode: userProfileData?.phoneCountryCode ?? '1',
      emailAddress: getUserEmail() ?? null,
      agencyType: getAgencyType(),
      employeeType: userProfileData?.employeeType ?? null,
      address: userProfileData?.address ?? null,
      addressTwo: userProfileData?.addressTwo ?? null,
      city: userProfileData?.city ?? null,
      state: userProfileData?.state ?? null,
      zipcode: userProfileData?.zipcode ?? null,
      country: userProfileData?.country ?? 'US',
      usaceHQ: userProfileData?.areaOfInterest?.length === (districtsArr.length + divisions.length) ? true : false,
      division: getDivisions() ?? null,
      district: getDistricts() ?? null,
    };

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur', stateOptions: [] });
    const { formState: { errors, isValid }, setValue, setFocus, watch, getValues, trigger } = methods;

    const watchRole = watch('role');
    const watchAddress = watch('address', '');
    const watchAddress2 = watch('addressTwo', '');
    const watchCity = watch('city', '');
    const watchState = watch('state', '');
    const watchZipcode = watch('zipcode', '');
    const watchCountry = watch('country', '');
    const [stateOptions, setStateOptions] = useState([userProfileData?.state]);

    const doSave = () => {
      if (isValid) {
        const values = getValues();
        if (values.division.length < 1) {
          setValue('usaceHQ', true);
          const allDistricts = districtsArr.map(val => val.value);
          const allDivisions = divisions.map(val => val.value);
          const areasofinterest = allDivisions.concat(allDistricts);
          const agencytypes = values.agencyType.map(val => val.value);
          const { usaceHQ, district, division, agencyType, ...fixedList } = values;

          const mappedValues = {
            ...fixedList,
            areaOfInterest: areasofinterest,
            agencyType: agencytypes,
            phone: values?.phone?.replace(/\D/g, ''),
          };

          if (edit) {
            doUpdateUserProfile(mappedValues);
          } else {
            doSaveUserProfile(mappedValues);
          }

        }
        else {
          const districts = values.district.map(val => val.value);
          const divisions = values.division.map(val => val.value);
          const areasofinterest = divisions.concat(districts);
          const agencytypes = values.agencyType.map(val => val.value);
          const { usaceHQ, district, division, agencyType, ...fixedList } = values;

          const mappedValues = {
            ...fixedList,
            areaOfInterest: areasofinterest,
            agencyType: agencytypes,
            phone: values?.phone?.replace(/\D/g, ''),
          };

          if (edit) {
            doUpdateUserProfile(mappedValues);
          } else {
            doSaveUserProfile(mappedValues);
          }
        }
      }
      else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    useEffect(() => {
      edit && trigger();
    }, [edit, trigger]);

    const handleBlur = (e) => {
      const str = e?.target?.value.trim();
      const trimmedStr = str?.replace(/^[^\p{L}\p{N}]+|[^\p{L}\p{N}]+$/gu, '');
      setValue(e?.target?.name, trimmedStr);
    };

    const handleCountryChange = useCallback((selectedCountry) => {
      const selectedCountryData = countryRegionData.find(country => country.countryShortCode === selectedCountry);
      const regions = [];

      if (selectedCountryData) {
        regions.push(...selectedCountryData.regions?.map(region => ({
          value: region.shortCode,
          text: region.name
        })));
      }
      regions.push({ value: 'Other', text: 'Other' });
      regions.push({ value: 'None', text: 'None' });

      setStateOptions(regions);

      if (selectedCountry !== userProfileData?.country) {
        setValue('city', '');
        setValue('state', '');
        setValue('zipcode', '');
      }
    }, [userProfileData, setValue]);

    const showCancelButton = userProfileData?.role === ProfileRoles.ExternalAgency ? false : edit;

    useEffect(() => {
      if (userProfileData?.country) {
        handleCountryChange(userProfileData.country);
      }
    }, [userProfileData, handleCountryChange]);

    useEffect(() => {
      const matchingOption = stateOptions?.find(option => option?.value === userProfileData?.state);
      if (matchingOption) {
        setValue('state', matchingOption?.value);
      }
    }, [userProfileData?.state, stateOptions, setValue]);

    useEffect(() => {
      const fields = addressCandidateResults?.candidates?.[0]?.attributes;
      const addressField = document.getElementById('address');
      const addressFieldFocused = document.activeElement === addressField;

      if (fields) {

        if (addressFieldFocused) {
          addressField.blur();
        }

        setValue('address', fields.StAddr, { shouldValidate: true });
        setValue('addressTwo', fields.SubAddr, { shouldValidate: true });
        setValue('city', fields.City, { shouldValidate: true });
        setValue('state', fields.RegionAbbr, { shouldValidate: true });
        setValue('zipcode', fields.Postal, { shouldValidate: true });
        setValue('county', fields.Subregion, { shouldValidate: true });
        setValue('country', mapCountryAlpha3toAlpha2(fields.Country), { shouldValidate: true });
        doResetAddressCandidatesResults();

        if (addressFieldFocused) {
          addressField.focus();
        }
      }

    }, [addressCandidateResults, doResetAddressCandidatesResults, setValue]);

    return (
      <ModalContent hasCloseButton={!showCancelButton} isLogout={!isUserRoleValid} title='User Profile'>
        <FormProvider {...methods}>
          {errors && <ErrorSummary errors={errors} modalID='profileModal' type='modal' />}
          <section className='modal-body' id='profileModal'>
            <div className='container-fluid'>
              <p>Tell us a little about yourself by completing the fields below.</p>
              <p><span className='text-bold'>Note:</span> You will not be able to access the RRS application until you complete this form.</p>
              <div className='row'>
                <div className='col-4'>
                  <SelectInput name='role' label='Role' required tooltip={rolesHelperContent}>
                    {(roleOptions(getUserEmail()) === true) ?
                      <option key='2' value={ProfileRoles.USACERegulator}>{ProfileRoles.USACERegulator}</option>
                      : <>
                        <option key='2' value={ProfileRoles.Applicant}>{ProfileRoles.Applicant}</option>
                        <option key='3' value={ProfileRoles.Agent}>{ProfileRoles.Agent}</option>
                      </>}
                  </SelectInput>
                </div>
              </div>

              {(watchRole || userProfileData?.role === ProfileRoles.ExternalAgency) && (
                <>
                  <p className='h6 border-bottom w-100 pb-2 mt-3'>Name</p>
                  <Alert type='warning' slim>
                    If you wish to update your first name, last name, or email in your profile, please contact the RRS help desk at <LinkButton onClick={(e) => { window.location.href = 'mailto:rrs@usace.army.mil?subject=RRS%20Support%20Ticket'; e?.preventDefault(); }} title='rrs@usace.army.mil' content='rrs@usace.army.mil' /> for assistance.
                  </Alert>
                  <div className='row'>
                    <div className='col-4'>
                      <SelectInput name='salutation' label='Salutation' required>
                        {salutations.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
                      </SelectInput>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <InputField name='firstName' label='First Name' type='text' required readOnly />
                    </div>
                    <div className='col-4'>
                      <InputField name='middleName' label='Middle Name' type='text' onBlur={handleBlur} />
                    </div>
                    <div className='col-4'>
                      <InputField name='lastName' label='Last Name' type='text' required readOnly />
                    </div>
                  </div>
                  <p className='h6 border-bottom w-100 pb-2 mt-3'>{watchRole === ProfileRoles.ExternalAgency ? 'Agency' : watchRole === ProfileRoles.USACERegulator ? 'Employee' : 'Company'} Information</p>
                  {watchRole === ProfileRoles.ExternalAgency && (
                    <div className='row'>
                      <div className='col'>
                        <MultiSelectInput name='agencyType' label='Agency Type' className='mb-3' required={watchRole === ProfileRoles.ExternalAgency}
                          options={[{ value: 'Commenting Agency', label: 'Commenting Agency' }, { value: 'Consulting Agency', label: 'Consulting Agency' }, { value: 'Certifying Agency', label: 'Certifying Agency' }]}
                          multi />
                      </div>
                    </div>
                  )}
                  {watchRole !== ProfileRoles.USACERegulator && (
                    <>
                      <div className='row'>
                        <div className='col'>
                          <InputField name={watchRole === ProfileRoles.ExternalAgency ? 'agencyName' : 'companyName'} label={watchRole === ProfileRoles.ExternalAgency ? 'Agency Name' : 'Company Name'} type='text' required={watchRole === ProfileRoles.ExternalAgency} onBlur={handleBlur} />
                        </div>
                      </div>
                    </>
                  )}
                  {watchRole !== ProfileRoles.USACERegulator && (
                    <>
                      <div className='row'>
                        <div className='col'>
                          <PhoneInputField showInputFormError={userProfileData?.role !== ProfileRoles.USACERegulator} phoneName='phone' prefixName='phoneCountryCode' label='Phone Number' value={userProfileData?.phone ?? ''} required />
                        </div>
                      </div>
                    </>

                  )}
                  <div className='row'>
                    {watchRole === ProfileRoles.USACERegulator && (
                      <div className='col-6'>
                        <SelectInput name='employeeType' label='Employee Type' required className='mb-3' options={['Request Manager', 'Project Manager', 'Supervisor', 'Peer Reviewer/Team Lead', 'Division', 'Headquarters']} />
                      </div>
                    )}
                    <div className='col-6'>
                      <InputField name='emailAddress' label='Email' type='email' maxLength={256} required readOnly onBlur={handleBlur} />
                    </div>
                  </div>
                  {watchRole !== ProfileRoles.USACERegulator && (
                    <>
                      <div className='row'>
                        <div className='col-12 mt-2'>
                          <AddressSuggestInput name='address' label='Street Address' required usOnly={false} onBlur={handleBlur} />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-12'>
                          <InputField name='addressTwo' label='Street Address Line 2' type='text' onBlur={handleBlur} />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <SelectInput name={'country'} label='Country' required onChange={(e) => handleCountryChange(e.target.value)}>
                            {countries.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
                          </SelectInput>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-4'>
                          <InputField name='city' label='City' type='text' maxLength={26} required onBlur={handleBlur} />
                        </div>
                        {watchCountry === 'US' ? (
                          <div className='col-4'>
                            <SelectInput name='state' label='State' required>
                              {states?.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
                            </SelectInput>
                          </div>
                        ) : (
                          <div className='col-4'>
                            <SelectInput name='state' label='Region' required>
                              {stateOptions?.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
                            </SelectInput>
                          </div>
                        )}
                        <div className='col-4'>
                          <InputField type='tel' name='zipcode' label={watchCountry === 'US' ? 'Zip Code' : 'Postal code'} required={watchCountry === 'US'} maxLength={watchCountry === 'US' ? 5 : 10} />
                        </div>
                      </div>
                      <div className='mt-2'>
                        <AddressValidator source='modal' address={{ address1: watchAddress, address2: watchAddress2, city: watchCity, state: watchState, zipcode: watchZipcode }} />
                      </div>
                    </>
                  )}
                  {(watchRole === ProfileRoles.ExternalAgency || watchRole === ProfileRoles.USACERegulator) && (
                    <>
                      <h6 className='border-bottom w-100 pb-2'>Areas of Interest <Tooltip name='AOI' content={<p><span className='text-bold'>Note:</span> If you do not specify the division/district that you are affiliated with, you will automatically be aligned under USACE HQ as your area of interest.</p>} /> </h6>
                      <div className='row'>
                        <div className='form-group w-100 pl-4 m-0 col-6'>
                          <CheckboxInput name='usaceHQ' label='Are you with USACE Headquarters?' />
                        </div>
                      </div>
                      <AreasOfInterestSelect divisionList={getDivisions()} districtList={getDistricts()} />
                    </>
                  )}
                </>
              )
              }
            </div >
          </section >
        </FormProvider >
        <ModalFooter
          showCancelButton={showCancelButton}
          onSave={doSave}
          saveText={edit ? 'Apply Changes' : 'Save'}
          customClosingLogic={true}
          isDisabled={!isValid}
        />
      </ModalContent >
    );
  }
);

export default ProfileModal;
