import { Icon } from '@trussworks/react-uswds';
import './secondaryModal.scss';

const ModalHeader = ({ title = '', hasIcon = false, isError = false }) => (
  <header id='secondary-modal-header' className='secondary-modal-header'>
    <h5 className='modal-title'>
      {hasIcon && <Icon.Help className='secondary-modal-icon' color={isError ? '#D43929' : '#005ea2'} />}
      {title}
    </h5>
  </header>
);

export default ModalHeader;
