import Card from '@components/card';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';
import classNames from 'classnames';
import { Button } from '@trussworks/react-uswds';
import '@styles/_buttons.scss';

const NewSectionCard = ({
  url,
  title,
  content,
  buttonText,
  buttonTitle,
  onClick = () => {},
  isDisabled,
  className,
  popupText,
  external,
}) => {
  const handleClick = (e) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      onClick();
    }
  };

  const cssClasses = classNames('card-style', className, {
    'form-btn-disabled': isDisabled,
  });

  return (
    <Card className={cssClasses}>
      <div className='static-container' style={{ height: '200px' }}>
        <h6 className='mt-3'>{title}</h6>
        <hr className='header-border' />
        {popupText && <div className='button-text popup center'>{popupText}</div>}
        <p style={{ fontSize: 'small' }} className='card-text'>
          {content}
        </p>
        {buttonText && (
          <div style={{ float: 'right' }}>
            <Button
              className='new-section-button'
              title={buttonText}
              size='small'
              disabled={isDisabled}
              onClick={handleClick}
            >
              <>
                {buttonText} {external && <Icon path={mdiOpenInNew} size={'16px'} />}
              </>
            </Button> 
          </div>
        )}
      </div>
    </Card>
  );
};

export default NewSectionCard;
