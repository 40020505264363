import React, { useEffect, useCallback } from 'react';
import Tooltip from '../tooltip/tooltip';
import { useFormContext } from 'react-hook-form';
import { formatUSPhoneNumber } from '../../utils/helpers';

import './inputs.scss';
import '../../styles/index.scss';
import './phoneInputField.scss';

const PhoneInputField =
  ({
    className = 'w-100',
    prefixName,
    phoneName,
    onChange = () => { },
    onBlur = () => { },
    label,
    tooltip,
    required,
    tooltipClickable,
    readOnly,
    uppercase,
  }) => {
    const { register, watch, setValue, formState: { errors }, trigger } = useFormContext();

    const prefixError = errors[prefixName];
    const phoneError = errors[phoneName];

    const inputError = errors[phoneName] || errors[prefixName];
    const prefixVal = watch(prefixName);
    const phoneVal = watch(phoneName);

    const calculateMaxLength = useCallback(() => {
      const absoluteMax = 15;
      const phoneMax = absoluteMax - prefixVal?.length;
      const inValid = isNaN(phoneMax);
      const returnVal = inValid ? 14 : phoneMax;

      return returnVal;

    }, [prefixVal?.length]);

    useEffect(() => {
      const phoneVal = watch(phoneName);
      if (prefixVal === '1' && phoneVal?.replace(/\D/g, '')?.length > 1) {
        if (phoneVal.replace(/\D/g, '').length <= 10) {
          setValue(phoneName, formatUSPhoneNumber(phoneVal), { shouldValidate: true });
        }
        else {
          setValue(phoneName, formatUSPhoneNumber(phoneVal.substring(0, 10)), { shouldValidate: true });
        }
      }
    }, [prefixVal, watch, setValue, phoneName]);

    useEffect(() => {

      if (phoneVal?.replace(/\D/g, '')?.length > calculateMaxLength()) {
        setValue(phoneName, phoneVal.substring(0, calculateMaxLength(), { shouldValidate: true }));
      }

    }, [phoneName, phoneVal, setValue, calculateMaxLength]);

    const handleCountryCodeChange = (e) => {
      onChange(e);
    };

    const isValidPhoneKey = (key) =>
      /[\d]|Backspace/.test(key);

    const handlePhoneChange = (e) => {
      const key = e?.key;
      const value = e?.target?.value;

      if (!isValidPhoneKey(key)) {
        e.preventDefault();
      }
      if (prefixVal === '1') {
        const formattedNumber = formatUSPhoneNumber(value);
        setValue(phoneName, formattedNumber, { shouldValidate: true });
        onChange(e);
      }
      else {
        const digitsOnly = value?.replace(/\D/g, '');
        setValue(phoneName, digitsOnly, { shouldValidate: true });
        onChange(e);
      }
    };

    const handlePhoneBlur = (e) => {
      onBlur(e);
      trigger(phoneName);
    };

    const handleCountryCodeBlur = (e) => {
      if (phoneVal?.replace(/\D/g, '')?.length > calculateMaxLength()) {
        setValue(phoneName, phoneVal.substring(0, calculateMaxLength()), { shouldValidate: true });
      }
      if (prefixVal !== '1') {
        setValue(phoneName, phoneVal?.replace(/\D/g, ''), { shouldValidate: true });
      }
      onBlur(e);
      trigger(prefixName);
    };

    const handlePhoneKeyDown = (e) => {
      if (!isValidPhoneKey(e.key)) {
        e.preventDefault();
      }
    };

    return (
      <>
        <label
          className={'ml-3 mb-0 mt-2 w-100'}
          htmlFor={phoneName}>
          {label}{required ? <span className='asterisk-color'>*</span> : <span style={{ fontStyle: 'italic' }}> (optional)</span>}
          {tooltip && (
            <Tooltip name={phoneName} content={tooltip} clickable={tooltipClickable} iconStyle={{ marginLeft: '5px' }} />
          )}
        </label>
        <div style={{ position: 'relative', marginTop: '-5px' }}>
          <div className='d-flex flex-row mt-2 align-items-center'>
            <div className='mb-4' >
              +
            </div>
            <div className='ml-1' style={{ width: '75px' }}>
              <input
                type='text'
                maxLength={3}
                onKeyDown={(e) => handlePhoneKeyDown(e)}
                className={`form-control ${className} ${prefixError ? 'is-invalid' : ''} ${uppercase ? 'uppercase' : ''}`}
                name={prefixName}
                id={prefixName}
                readOnly={readOnly}
                required={required}
                defaultValue={prefixVal ? prefixVal : (required ? 1 : null)}
                aria-invalid={inputError ? 'true' : 'false'}
                aria-required={required}
                {...register(prefixName, { onChange: handleCountryCodeChange, onBlur: handleCountryCodeBlur })}
              />
              <label htmlFor={prefixName} className='small-label'>Country Code</label>
            </div>
            <div className='ml-2 mw-50'>
              <input
                type='text'
                id={phoneName}
                name={phoneName}
                maxLength={prefixVal === '1' ? 14 : calculateMaxLength()}
                onChange={(e) => handlePhoneChange(e)}
                className={`form-control ${className} ${phoneError ? 'is-invalid' : ''} ${uppercase ? 'uppercase' : ''}`}
                readOnly={readOnly}
                required={required}
                aria-invalid={phoneError ? 'true' : 'false'}
                aria-required={required}
                {...register(phoneName, { onChange: handlePhoneChange, onBlur: handlePhoneBlur })}
              />
              <label htmlFor={phoneName} className='small-label'>Area Code and Phone Number</label>
            </div>
          </div>
        </div >
      </>
    );
  };
export default PhoneInputField;
