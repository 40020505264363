import { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@components/new-inputs/contactFieldsnew';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { ContactTypes, ProfileRoles } from '@src/utils/enums';
import { addressRegex, addressTwoRegex, emailRegex, phoneRegex, countryCodeRegex, usPhoneRegex, phoneExtRegex, cityRegex, zipCodeRegex } from '@src/utils/regex';
import { hasDuplicateAgent, isCurrentUser } from './_shared/UserProfileAgent';
import ErrorSummary from '@components/error-summary/ErrorSummary';



const schema = yup.object().shape({
  firstName: yup.string().required('Field is required'),
  lastName: yup.string().required('Field is required'),
  address: yup.string().required('Field is required').matches(addressRegex, 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.'),
  addressTwo: yup.string().nullable().matches(addressTwoRegex, 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.'),
  city: yup.string().matches(cityRegex, 'Field is invalid').required('Field is required'),
  state: yup.string().when('country', { is: 'US', then: () => yup.string().required('Please select an option'), otherwise: () => yup.string().required('Please select an option') }),
  zipcode: yup.string().when('country', { is: 'US', then: () => yup.string().matches(zipCodeRegex, 'Field is invalid'), otherwise: () => yup.string().nullable() }),
  country: yup.string().required('Please select an option'),
  phoneOneType: yup.string().required('Field is required'),
  phoneOneCountryCode: yup.string().required('Field is required').matches(countryCodeRegex, 'Field is invalid'),
  phoneOne: yup.string().when('phoneOneCountryCode', {
    is: '1',
    then: () => yup.string().matches(usPhoneRegex, 'Field is invalid'),
    otherwise: () => yup.string().matches(phoneRegex, 'Field is invalid')
  }).required('Field is required'),
  phoneOneExtension: yup.string().nullable().when('phoneOneType', { is: 'Business', then: () => yup.string().matches(phoneExtRegex, 'Field is invalid') }),
  showPhoneTwo: yup.boolean().nullable(),
  phoneTwoType: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Field is required') }),
  phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', { is: true, then: () => yup.string().required('Field is required').matches(countryCodeRegex, 'Field is invalid') }),
  phoneTwo: yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
    is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true,
    then: () => yup.string().required('Field is required').matches(usPhoneRegex, 'Field is invalid'),
    otherwise: () => yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
      is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true,
      then: () => yup.string().required('Field is required').matches(phoneRegex, 'Field is invalid')
    })
  }),
  phoneTwoExtension: yup.string().nullable().when(['showPhoneTwo', 'phoneTwoType'], { is: (show, type) => show && type === 'Business', then: () => yup.string().nullable().matches(phoneExtRegex, { message: 'Field is invalid', excludeEmptyString: true }) }),
  faxPhone: yup.string().matches(phoneRegex, { message: 'Field is invalid', excludeEmptyString: true }).nullable(),
  emailAddress: yup.string().required('Field is required').matches(emailRegex, 'Field is invalid'),
});

const AddAgentModal = connect(
  'selectUserProfileData',
  'doModalClose',
  ({
    edit,
    data,
    setRowData,
    rowData,
    id,
    isReadOnly,
    userProfileData,
    doModalClose,
  }) => {

    const defaultValues = edit ? {
      contactType: ContactTypes.Agent,
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      company: data?.company ?? null,
      emailAddress: data?.emailAddress ?? null,
      faxPhone: data?.faxPhone ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      phoneOne: data?.phoneOne ?? null,
      phoneOneType: data?.phoneOneType ?? null,
      phoneOneCountryCode: data?.phoneOneCountryCode ?? '1',
      phoneOneExtension: data?.phoneOneExtension ?? null,
      phoneTwoCountryCode: data?.phoneTwoCountryCode ?? null,
      phoneTwo: data?.phoneTwo ?? null,
      phoneTwoType: data?.phoneTwoType ?? null,
      phoneTwoExtension: data?.phoneTwoExtension ?? null,
      salutation: data?.salutation ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? null,
      country: data?.country ?? 'US',
    } : {
      contactType: ContactTypes.Agent, country: 'US'
    };

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors, isValid }, setFocus, getValues, trigger } = methods;

    const onSave = () => {
      const contactFields = getValues();
      if (userProfileData?.role !== ProfileRoles.Agent && isCurrentUser(userProfileData, contactFields)) {
        trigger();
        toast.error('You cannot add yourself as an agent!');
        return;
      }

      if (isValid) {
        if (!edit && hasDuplicateAgent(rowData, contactFields)) {
          trigger();
          toast.error('Cannot allow duplicate Agents!');
          return;
        }

        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
          contactType: ContactTypes.Agent,
          createdBy: data?.createdBy ?? undefined,
          phoneOne: contactFields?.phoneOne?.replace(/\D/g, '') ?? undefined,
          phoneTwo: contactFields?.phoneTwo?.replace(/\D/g, '') ?? undefined,
        };

        if (edit) {
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex(el => el.contactID === id) : id;
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        } else {
          setRowData((rowData) => [...rowData, contactFieldsWithIDs]);
        }
        doModalClose();
      } else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
        errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
      }

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent hasCloseButton={isReadOnly} title={`${edit ? 'Edit' : 'Add'} Agent`}>
          {errors && <ErrorSummary errors={errors} modalID='addAgentModal' type='modal' />}
          <section className='modal-body' id='addAgentModal'>
            <div className='container-fluid'>
              <ContactFields showButton={userProfileData.role === ContactTypes.Agent} type='Agent' label='Agent' edit={edit} isReadOnly={isReadOnly} />
            </div>
          </section>
          <ModalFooter
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
            saveText={edit ? 'Apply Changes' : 'Add'}
            customClosingLogic
            onSave={onSave}
          />
        </ModalContent>
      </FormProvider >
    );
  });

export default AddAgentModal;
