import { useEffect } from 'react';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@components/new-inputs/contactFieldsnew';

import { connect } from 'redux-bundler-react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ContactTypes } from '@src/utils/enums';
import { addressRegex, addressTwoRegex, cityRegex, zipCodeRegex } from '@src/utils/regex';
import ErrorSummary from '@components/error-summary/ErrorSummary';

const schema = yup.object().shape({
  firstName: yup.string().required('Field is required'),
  middleName: yup.string().nullable(),
  lastName: yup.string().required('Field is required'),
  address: yup.string().required('Field is required').matches(addressRegex, 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.'),
  addressTwo: yup.string().nullable().matches(addressTwoRegex, 'Only letters, numbers, spaces, commas, periods, hyphens, apostrophes, forward slashes, and hash symbols are allowed.'),
  city: yup.string().matches(cityRegex, 'Field is invalid').required('Field is required'),
  state: yup.string().when('country', { is: 'US', then: () => yup.string().required('Please select an option'), otherwise: () => yup.string().required('Please select an option') }),
  zipcode: yup.string().when('country', { is: 'US', then: () => yup.string().matches(zipCodeRegex, 'Field is invalid'), otherwise: () => yup.string().nullable() }),
  country: yup.string().required('Please select an option'),
});

const AddAdjoiningPropertyOwnerModal = connect(
  'doModalClose',
  ({
    doModalClose,
    edit,
    data,
    setRowData,
    rowData,
    id,
    isReadOnly
  }) => {
    const defaultValues = edit ? {
      contactType: ContactTypes.AdjoiningPropertyOwner,
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? null,
      country: data?.country ?? 'US',
    } : {
      contactType: ContactTypes.AdjoiningPropertyOwner,
      country: 'US'
    };

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors, isValid }, setFocus, getValues, trigger } = methods;

    const onSave = () => {
      if (isValid) {
        const contactFields = getValues();
        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
          contactType: ContactTypes.AdjoiningPropertyOwner,
          createdBy: data?.createdBy ?? undefined,
        };
        if (edit) {
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex(el => el.contactID === id) : id;
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        }
        else {
          setRowData((rowData) => [...rowData, contactFieldsWithIDs]);
        }
        doModalClose();
      }
      else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
        errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
      }

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent hasCloseButton={isReadOnly} size='lg' title={`${edit ? 'Edit' : 'Add'} ${ContactTypes.AdjoiningPropertyOwner}`}>
          {errors && <ErrorSummary errors={errors} modalID='adjoiningPropertyOwnerModal' type='modal' />}
          <section className='modal-body' id='adjoiningPropertyOwnerModal'>
            <div className='container-fluid'>
              <ContactFields type={ContactTypes.AdjoiningPropertyOwner} showContactInfoSection={false} showSalutation={false} edit={edit} isReadOnly={isReadOnly} />
            </div>
          </section>
          <ModalFooter
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
            saveText={edit ? 'Apply Changes' : 'Add'}
            customClosingLogic
            onSave={onSave}
          />
        </ModalContent>
      </FormProvider >
    );
  }
);

export default AddAdjoiningPropertyOwnerModal;
