import React, { useRef, useEffect } from 'react';

import Icon from '@mdi/react';
import { mdiTrashCan, mdiDownload } from '@mdi/js';

import { FileTypes } from '@src/utils/enums';

import '../../../Dashboard/dashboard.scss';
import './cellRenderers.scss';

const EditSupportingDocsCellRenderer = ({
  doDeleteFile,
  doDownloadFile,
  data,
  isReadOnly,
  api,
  column,
  rowIndex,
}) => {
  const iconRef1 = useRef(null);
  const iconRef2 = useRef(null);

  const handleTab = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      if (document.activeElement === iconRef1.current) {
        iconRef1.current.focus();
      } else {
        iconRef2.current.focus();
      }
    }
  };

  useEffect(() => {
    const onCellFocused = (params) => {
      if (params.column.colId === column.colId && params.rowIndex === rowIndex) {
        iconRef1.current.focus();
      }
    };
    api.addEventListener('cellFocused', onCellFocused);
    return () => {
      api.removeEventListener('cellFocused', onCellFocused);
    };
  }, [api, column.colId, rowIndex]);

  const handleKeyUp = (e, iconIndex) => {
    switch (e.key) {
      case 'Enter' && iconIndex === 1:
        doDownloadFile(data?.fileName, FileTypes.Request);
        break;
      case 'Enter' && iconIndex === 2:
        doDeleteFile(data?.fileName, data?.componentID);
        break;
      case 'ArrowRight' && iconIndex === 1:
        iconRef2.current.focus();
        break;
      case 'ArrowLeft' && iconIndex === 2:
        iconRef1.current.focus();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {data?.key && (
        <div className='renderer-div'>
          <div role='button' title='Download File' onClick={() => doDownloadFile(data?.fileName, 'Request')} onKeyUp={e => handleKeyUp(e, 1)} onKeyDown={handleTab} tabIndex={0} ref={iconRef1}>
            <Icon className='cell-btn-icon info' path={mdiDownload} size={'28px'} />
          </div>
          {!isReadOnly && <div role='button' title='Delete File' onClick={() => doDeleteFile(data?.fileName, data?.componentID)} onKeyUp={e => handleKeyUp(e, 2)} onKeyDown={handleTab} ref={iconRef2} tabIndex={1}>
            <Icon className='cell-btn-icon delete' path={mdiTrashCan} size={'28px'} />

          </div>}
        </div >
      )}
    </>
  );
};

export default EditSupportingDocsCellRenderer;
