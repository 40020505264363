import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import { ModalContent, ModalFooter } from '@components/modal';
import SelectInput from '@components/new-inputs/selectInput';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Alert } from '@trussworks/react-uswds';
import DistrictModal from '@src/app-components/map/modals/districtModal';

import { latRegex, lngRegex, cityRegex, zipCodeRegex, decimalNumberRegex } from '@src/utils/regex';
import ProjectLocationTab from '@pages/Home/components/modals/modal-tabs/ProjectLocationTab';
import { formatCoordFlt } from '@src/utils/helpers';

const isAnonymousModal = connect(
  'doUpdateSelectedRequest',
  'doAuthLogin',
  'doUpdateIsAnonymous',
  'doResetStepper',
  'doUpdateRequestObject',
  'doResetReduxFormData',
  'doUpdateRequestData',
  'doAddViolationComplaint',
  'doAddAnonymousViolationComplaint',
  'doUpdateProjectAddressObject',
  'doUpdateRequestLocation',
  'doUpdateComplexStateField',
  'doSecondaryModalOpen',
  'selectAuthIsLoggedIn',
  'selectProjectGeometry',
  'selectDistrictResults',
  'selectPlssResults',
  ({
    doUpdateSelectedRequest,
    doAuthLogin,
    doUpdateIsAnonymous,
    doResetStepper,
    doUpdateRequestObject,
    doResetReduxFormData,
    doUpdateRequestData,
    doAddViolationComplaint,
    doAddAnonymousViolationComplaint,
    doUpdateProjectAddressObject,
    doUpdateRequestLocation,
    doUpdateComplexStateField,
    doSecondaryModalOpen,
    authIsLoggedIn,
    projectGeometry,
    districtResults,
    plssResults,
  }) => {
    const [projectOutsideUS, setProjectOutsideUS] = useState(false);
    const [district, setDistrict] = useState();

    const defaultValues = {
      country: 'US',
      showAddressInput: false,
      showGDBInput: false,
      showMapInput: false,
    };

    useEffect(() => {
      doResetReduxFormData();
    }, [doResetReduxFormData]);

    const schema = yup.object().shape({
      showAddressInput: yup.boolean().nullable(),
      showMapInput: yup.boolean().nullable(),
      showGDBInput: yup.boolean().nullable(),
      addressInputType: yup.string().nullable().when('showAddressInput', {
        is: true,
        then: () => yup.string().required('Please select an option'),
      }),
      address: yup.string().nullable().when('addressInputType', {
        is: val => val?.includes('address'),
        then: () => yup.string().required('Field is required'),
      }),
      addressTwo: yup.string().nullable(),
      city: yup.string().nullable().when('addressInputType', {
        is: val => val?.includes('address'),
        then: () => yup.string().matches(cityRegex, { message: 'Field is invalid', excludeEmptyString: true }).required('Field is required'),
      }),
      state: yup.string().nullable().when('addressInputType', {
        is: val => val?.includes('address'),
        then: () => yup.string().required('Please select an option'),
      }),
      zipcode: yup.string().nullable().when('addressInputType', {
        is: val => val?.includes('address'),
        then: () => yup.string().matches(zipCodeRegex, { message: 'Field is invalid', excludeEmptyString: true }).required('Field is required'),
      }),
      county: yup.string().nullable().matches(cityRegex, { message: 'Field is invalid', excludeEmptyString: true }),
      country: yup.string().nullable().when('addressInputType', {
        is: val => val?.includes('address'),
        then: () => yup.string().required('Please select an option'),
      }),
      latitude: yup.string().when('addressInputType', {
        is: val => val?.includes('coordinates'),
        then: () => yup.string().required('Field is required').test({
          name: 'latInRange',
          exclusive: true,
          message: 'Latitude must be between 0 and 90',
          test: val => !projectOutsideUS ? Number(val) <= 90 && Number(val) >= 0 : true,
        }).test('latFormat', 'Format is incorrect. Must be +-XX.XXXXXX and include at least 6 decimal places.', val => latRegex.test(val)),
      }),
      longitude: yup.string().when('addressInputType', {
        is: val => val?.includes('coordinates'),
        then: () => yup.string().required('Field is required').test({
          name: 'lngInRange',
          exclusive: true,
          message: 'Longitude must be between -180 and 0',
          test: val => !projectOutsideUS ? Number(val) <= 0 && Number(val) >= -180 : true,
        }).test('lngFormat', 'Format is incorrect. Must be +-XXX.XXXXXX and include at least 6 decimal places.', val => lngRegex.test(val)),
      }),
      projectArea: yup
        .string()
        .nullable()
        .matches(decimalNumberRegex, { message: 'Field is invalid', excludeEmptyString: true })
        .test('is-required', 'Area of Violation required when Unit of Measurement has been entered', function (value) {
          const { unitOfMeasurement } = this.parent;
          return !unitOfMeasurement || (value !== '' && value !== null);
        }),
      unitOfMeasurement: yup
        .string()
        .nullable()
        .test('is-required', 'Unit of Measurement required when Area of Violation has been entered', function (value) {
          const { projectArea } = this.parent;
          return !projectArea || (value !== '' && value !== null);
        }),
      parcelNumber: yup.string().nullable(),
      nearestWaterbody: yup.string().nullable(),
      fieldOfficeCode: yup.string().nullable(),
      siteDirections: yup.string().nullable(),
      plssSection: yup.string().nullable(),
      plssTownship: yup.string().nullable(),
      plssRange: yup.string().nullable(),
    }).test('at-least-one-input', 'Please select a value for either Address, Map, or GDB input', function (value) {
      const { showAddressInput, showMapInput, showGDBInput } = value;
      if (!showAddressInput && !showMapInput && !showGDBInput) {
        return this.createError({ path: 'inputType', message: 'Please select an option' });
      }
      return true;
    }, [['projectArea', 'projectArea']]);

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors, isValid }, setValue, setFocus, watch, getValues, trigger } = methods;
    const [isLoading, setIsLoading] = useState(false);
    const showGDBInput = watch('showGDBInput');
    const showMapInput = watch('showMapInput');
    const addressInputType = watch('addressInputType');
    const isAnonymous = watch('isAnonymous');
    const latitude = watch('latitude');
    const longitude = watch('longitude');

    useEffect(() => {
      const VCSession = sessionStorage.getItem('VCSession');
      VCSession && setValue('isAnonymous', 'No');
      sessionStorage.removeItem('VCSession');
    }, [setValue]);

    const handleClick = () => {
      if (isAnonymous === 'Yes' || authIsLoggedIn) {
        if (isValid && districtResults?.[0]) {
          const data = getValues();

          doUpdateComplexStateField({ name: 'isnewrequest', value: true });

          doUpdateSelectedRequest('6');

          doUpdateRequestObject(({ requestType: 'Violation Complaint' }));
          doUpdateRequestData({
            projectName: 'Report of Potential Enforcement',
            projectDescription: 'Report of potential unauthorized or non-compliance',
            natureOfActivity: 'Report of potential unauthorized or non-compliance',
            projectPurpose: 'Report of potential unauthorized or non-compliance',
          });
          doUpdateIsAnonymous(Boolean(isAnonymous === 'Yes'));

          const locationObj = {
            latitude: formatCoordFlt(data?.latitude),
            longitude: formatCoordFlt(data?.longitude),
            address: data?.address,
            addressTwo: data?.addressTwo,
            city: data?.city,
            state: data?.state,
            zipcode: data?.zipcode,
            county: data?.county,
            country: data?.country,
            district: district,
            fieldOfficeCode: data?.fieldOfficeCode,
            plssSection: plssResults?.FRSTDIVNO,
            plssTownship: plssResults?.TWNSHPNO,
            plssRange: plssResults?.RANGENO,
            projectArea: Number(data?.projectArea),
            unitOfMeasurement: data?.unitOfMeasurement,
            siteDirections: data?.siteDirections,
            parcelNumber: data?.parcelNumber,
            nearestWaterbody: data?.nearestWaterbody,
            geometry: projectGeometry,
          };

          doUpdateProjectAddressObject({
            projectName: 'Report of Potential Enforcement',
            ...locationObj
          });
          doUpdateRequestLocation(locationObj);
          isAnonymous === 'Yes' ? doAddAnonymousViolationComplaint() : doAddViolationComplaint();
          doResetStepper();
        }
        else if (isValid && !districtResults?.[0]) {
          alert('Location is not in a valid USACE district or district has not been determined yet. Please verify the project location and try again.');
        }
        else {
          trigger();
          toast.error('Please fill out all required fields!');
        };
      }
      else if (!isAnonymous) {
        trigger('isAnonymous');
        toast.error('Please select a value!');
      }
      else {
        sessionStorage.setItem('VCSession', true);
        doAuthLogin();
      }
    };

    useEffect(() => {
      if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
        errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
      }

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    useEffect(() => {

      let timer;
      if (latitude && longitude && !districtResults?.[0]) {
        setIsLoading(true);
        timer = setTimeout(() => { setIsLoading(false); alert('A USACE regulatory district could not be determined for this location. Please select a new location and try again.'); }, 5000);
      }
      else if (districtResults?.[0]) {
        setIsLoading(false);
        clearTimeout(timer);
      }
      return () => { clearTimeout(timer); };

    }, [districtResults, latitude, longitude]);

    useEffect(() => {
      if (districtResults?.length > 1 && (!district || !districtResults.some(d => d === district))) {
        doSecondaryModalOpen(DistrictModal, { setDistrict });
      } else if (districtResults?.length === 1 && districtResults?.[0] !== district) {
        setDistrict(districtResults?.[0]);
      }
    }, [districtResults, district, doSecondaryModalOpen]);

    useEffect(() => {

      doUpdateRequestLocation({ district: district });

    }, [doUpdateRequestLocation, district]);

    return (
      <ModalContent className={(isAnonymous === 'Yes' || isAnonymous === 'No') && 'modal-resize'} title='Report Potential Violation'>
        <FormProvider {...methods}>
          <section className='modal-body'>
            <div className='container-fluid'>
              <SelectInput name='isAnonymous' label='Do you wish to remain anonymous' required>
                <option key='2' value='Yes'>Yes</option>
                <option key='3' value='No'>No</option>
              </SelectInput>
            </div>
            {isAnonymous === 'Yes' && <Alert slim noIcon type='warning' heading='NOTICE' headingLevel='h4'>You will not be receiving update notifications on your submission.</Alert>}
            {isAnonymous === 'No' && !authIsLoggedIn ? <Alert slim noIcon type='warning' heading='NOTICE' headingLevel='h4'>You will be prompted to sign up/login. If logged in, you can track your submissions in the dashboard.</Alert> : (isAnonymous === 'No' && authIsLoggedIn) ?
              <Alert slim noIcon type='warning' heading='NOTICE' headingLevel='h4'>Please note, if you reported an alleged violation, USACE does not discuss the details of ongoing enforcement investigations with third parties and status updates will not be provided.</Alert> : <></>
            }
            {(isAnonymous === 'Yes' || (authIsLoggedIn && isAnonymous === 'No')) ? <ProjectLocationTab activeTab={0} areaRequired={false} locationType='Site' setProjectOutsideUS={setProjectOutsideUS} /> : <></>}
          </section>
        </FormProvider>
        <ModalFooter
          showCancelButton
          saveText={(isAnonymous === 'Yes' || isAnonymous === undefined || authIsLoggedIn) ? 'Continue' : 'Sign Up/Login'}
          onSave={() => handleClick()}
          saveIsDisabled={(!(addressInputType === 'coordinates' || addressInputType === 'address') && !showMapInput && !showGDBInput)}
          customClosingLogic
          isLoading={isLoading}
        />
      </ModalContent >
    );
  });

export default isAnonymousModal;
