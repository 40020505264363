import React from 'react';
import { connect } from 'redux-bundler-react';
import { toast } from 'react-toastify';

import { Button } from '@trussworks/react-uswds';
import InputField from '@components/new-inputs/inputField';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

const schema = yup.object().shape({
  projectID: yup.string().required('Field is required'),
  requestID: yup.string().required('Field is required'),
  version: yup.string().required('Field is required'),
});

const defaultValues = {
  projectID: null,
  requestID: null,
  version: null
};

const GeneratePDF = connect(
  'doGeneratePDF',
  ({
    doGeneratePDF
  }) => {
    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur', stateOptions: [] });
    const { formState: { isValid }, setValue, watch, trigger } = methods;

    const projectID = watch('projectID');
    const requestID = watch('requestID');
    const version = watch('version');

    const handleSubmit = () => {
      if (isValid) {
        doGeneratePDF(Number(projectID), Number(requestID), Number(version));
        // Reset values
        setValue('projectID', null);
        setValue('requestID', null);
        setValue('version', null);
      } else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    return (
      <FormProvider {...methods}>
        <div className='row'>
          <div className='col-3'>
            <InputField name='projectID' label='Project ID' required />
          </div>
          <div className='col-3'>
            <InputField name='requestID' label='Request ID' required />
          </div>
          <div className='col-3'>
            <InputField name='version' label='Version' required />
          </div>
          <Button
            className='mt-4'
            title='Generate PDF'
            size='small'
            onClick={() => handleSubmit()}>
              Generate PDF
          </Button> 
        </div>
      </FormProvider>

    );
  });

export default GeneratePDF;

