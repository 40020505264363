import React from 'react';
import { connect } from 'redux-bundler-react';

import { Accordion } from '@trussworks/react-uswds';
import NewSectionCard from '../_shared/NewSectionCard';
import NewApplicationModal from '@pages/Home/components/modals/NewApplication';
import { ProfileRoles } from '@src/utils/enums';

import { SectionHeader } from '../_shared/helper.jsx';
import { geoExtentAccordion, scientificMethodsAccordion, submitOptionsAccordion, wotusAccordion } from './JurisdictionHelper';

import '@pages/Resources/resources.scss';
import '@styles/_accordion.scss';

const Jurisdiction = connect(
  'doAuthLogin',
  'doModalOpen',
  'doUpdateRelativeUrl',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  ({
    doAuthLogin,
    doModalOpen,
    doUpdateRelativeUrl,
    authIsLoggedIn,
    userProfileData
  }) => {
    const handleLink = () => {
      sessionStorage.setItem('ResourcesModuleSession', true);
      if (authIsLoggedIn) {
        const resourcesModuleSession = sessionStorage.getItem('ResourcesModuleSession');
        doUpdateRelativeUrl('/home');
        resourcesModuleSession && doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('ResourcesModuleSession');
      } else {
        doAuthLogin();
      }
    };

    return (
      <>
        <section id='understanding-corp-jurisdiction-id'>
          <SectionHeader>Understanding U.S. Army Corps of Engineers (USACE) Regulatory Authority and Jurisdiction</SectionHeader>
          <p>The USACE exercises regulatory authority over various types of aquatic resources, including rivers, lakes, waterways, and wetlands, throughout the United States and its territories. Explore the sections below to learn more about the USACE Regulatory authorities and the geographic extent of USACE Jurisdiction.</p>
        </section>
        <section id='waters-id'>
          <SectionHeader>Which waters does the USACE Regulate?</SectionHeader>
          <p>USACE has regulatory authority over two main categories of U.S. waters:</p>
          <Accordion bordered items={wotusAccordion} multiselectable={true} />
          <p className='margin-top-3'>The geographic extent of these waters are divided into two categories:</p>
          <Accordion bordered items={geoExtentAccordion} multiselectable={true} />
        </section>

        <section id='delineations-of-waters-id' className='margin-top-3'>
          <SectionHeader>How do I determine if regulated waters are on my project site?</SectionHeader>
          <p>A delineation of aquatic resources will identify any potentially regulated waters or wetlands on your project site. A delineation of aquatic resources is the act of identifying and mapping the extent of aquatic resources
            (such as rivers, streams, and wetlands) within a specified review area using scientific methods developed by the U.S. Army Corps of Engineers (USACE). An aquatic resources delineation report is a document or series of documents that describe the location and
            extent of aquatic resources in the review area, describes the methodology used to conduct the delineation, and typically includes maps, datasheets, photographs, and/or figures that depict the location and extent of aquatic resources on the site.</p>
          <p>A necessary step in the regulatory permitting process is identifying and delineating the aquatic resources on the site of proposed project.</p>
          <p>A delineation of aquatic resources is a required component of a permit application. The most effective way to obtain a timely permit decision is to submit an accurate aquatic resources
            delineation report with your permit application. Most applicants elect to retain an environmental consultant to prepare the aquatic resource delineation report, to ensure that the aquatic
            resources delineation methods have been correctly applied and that the report includes the information needed for the USACE to process a permit application.</p>
          <Accordion bordered items={scientificMethodsAccordion} multiselectable={true} />
        </section>

        <section id='jd-id' className='margin-top-3'>
          <SectionHeader>Can the USACE provide written assurance regarding the location of regulated waters on my project site?</SectionHeader>
          <p>A jurisdictional determination (JD) is a written USACE determination that a wetland and/or waterbody is subject to regulatory jurisdiction under Section 404 of the Clean Water
            Act (33 U.S.C. 1344) or Sections 9 or 10 of the Rivers and Harbors Act of 1899 (33 U.S.C. 401 et seq.).</p>
          <p>A jurisdictional determination is not required to apply for or obtain a permit. The USACE evaluates jurisdiction as part of our review of a permit application. If you have a question regarding the jurisdictional status or geographic boundary of an aquatic resource on your project site, please specify what questions you have in a cover letter that is attached to your permit application and the USACE will evaluate and address those questions as part of our review of the submittal.</p>

          <SectionHeader>My project site is large, complex, and/or contains multiple aquatic resources that may be regulated by USACE. What steps can I take to ensure that the permitting process is completed efficiently? </SectionHeader>
          <p>Many projects, such as those that are complex or may involve large impacts to aquatic resources, benefit from a pre-application consultation. During such a consultation, the USACE
            can provide information on ways to expedite, reduce, or eliminate regulatory requirements, including whether preparing a JD may reduce permitting requirements. The Regulatory Request
            System (RRS) provides a means to request a preapplication meeting in those cases.</p>
          <SectionHeader>What are my options to submit an Aquatic Resource Delineation Report or to request a Jurisdictional Determination?</SectionHeader>
          <p>The Regulatory Request System (RRS) offers two options associated with USACE jurisdiction: 1) "Submit an Aquatic Resource Delineation Report and Aquatic Resource Inventory" and 2) "Request for Jurisdictional Determination". The first option is designed for environmental consultants who have completed a delineation report on behalf of their client. The second option is designed for property owners who require a written determination of jurisdiction and/or property owners who might need a permit from the USACE and require assistance in identifying waters of the United States on their property. To submit a request, click the "Start a New Request" button below.</p>,
          <SectionHeader>What are the two types of Jurisdictional Determination?</SectionHeader>
          <Accordion bordered items={submitOptionsAccordion} multiselectable={true} className='margin-bottom-2' />
        </section >

        <section id='next-steps-id' className='margin-top-3'>
          <SectionHeader>What are my next steps?</SectionHeader>
          <div className='row margin-top-3'>
            <div className='col-lg-6 col-md-6 col-xs-12 margin-top-1'>
              <NewSectionCard
                title='Request a Jurisdictional Determination'
                content={`If you're ready to submit an Aquatic Resource Delineation Report and Aquatic Resource Inventory and/or request a Jurisdictional Determination, click here to ${authIsLoggedIn ? 'start a new request' : 'login'}.`}
                onClick={() => handleLink()}
                buttonText={authIsLoggedIn ? 'Start a New Request' : 'Login'}
                buttonTitle={authIsLoggedIn ? 'Start a New Request' : 'Login'}
                isDisabled={userProfileData?.role?.toLowerCase() === ProfileRoles.USACERegulator.toString().toLowerCase()}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-xs-12 margin-top-1'>
              <NewSectionCard
                title='Need Further Assistance'
                content='Locate your local USACE Regulatory office for further assistance.'
                url='https://regulatory.ops.usace.army.mil/offices/'
                buttonText='Office Locator'
                buttonTitle='LearnMore'
                external
              />
            </div>
          </div>
        </section>
      </>
    );
  });

export default Jurisdiction;
