import React, { useCallback, useEffect, useMemo } from 'react';
import useErrorFocus from '@src/customHooks/useErrorFocus';
import { connect } from 'redux-bundler-react';

import FieldHeader from '@pages/Forms/components/Form/FieldHeader';
import statusModal from '@pages/Forms/components/modals/statusModal';
import InputField from '@components/new-inputs/inputField';
import MultiSelectInput from '@components/new-inputs/multiSelectInput';
import SelectInput from '@components/new-inputs/selectInput';
import TextAreaInput from '@components/new-inputs/textAreaInput';
import CreatableSelectInput from '@components/new-inputs/creatableSelectInput';
import Tooltip from '@components/tooltip/tooltip';
import CheckboxInput from '@components/new-inputs/checkboxInput';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';

import { requestreasons, ormErrorMsg, JDTypeHelperContent } from '@pages/Forms/input-forms/_helper';
import { projectDescriptionTooltipContent, projectPurposeTooltipContent, natureOfActivityTooltipContent } from '@pages/Resources/components/_shared/helper';

import '@src/styles/index.scss';
import { isValidORMNumber } from '@src/utils/helpers';
import ErrorSummary from '@components/error-summary/ErrorSummary';


export const JDFormGeneralProjectInformationMetadata = {
  sectionName: 'General Project Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const relationsOptionsList = [
  'I currently own this property.',
  'I plan to purchase this property.',
  'I am an agent/consultant acting on behalf of the applicant.',
  'Other'
];

const schema = yup.object().shape({
  projectName: yup.string().required('Field is required'),
  previousFileNumbersExist: yup.string().required('Please select an option'),
  previousFileNumbers: yup.array().when('previousFileNumbersExist', { is: 'true', then: () => yup.array().min(1, 'At least one Previous File Number is required').required('At least one Previous File Number is required') }),
  requestReason: yup.array().min(1, 'Please select at least one option'),
  otherRequestReason: yup.string().when('requestReason', { is: val => val?.map(val => val.value).includes('Other'), then: () => yup.string().required('Field is required') }).nullable(),
  relationshipToRequest: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', val => val.toUpperCase() !== 'DEFAULT_VALUE').required('Please select an option'),
  otherRelationshipToRequest: yup.string().when('relationshipToRequest', { is: 'Other', then: () => yup.string().required('Field is required') }).nullable(),
  projectDescription: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', val => val.toUpperCase() !== 'DEFAULT_VALUE').required('Field is required'),
  projectPurpose: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', val => val.toUpperCase() !== 'DEFAULT_VALUE').required('Field is required'),
  natureOfActivity: yup.string().test('isDefault', 'DEFAULT_VALUE is a reserved word, please enter a valid string', val => val.toUpperCase() !== 'DEFAULT_VALUE').required('Field is required'),
  JDTypeKnown: yup.string().required('Please select an option'),
  jurisdictionalDetermination: yup.array().when('JDTypeKnown', { is: 'true', then: () => yup.array().min(1, 'Please select at least one option') }),
});

const JDFormGeneralProjectInformation = connect(
  'doUpdateRequestObject',
  'doUpdateSectionValidity',
  'doUpdateJDRequest',
  'doUpdateRequestData',
  'doSecondaryModalOpen',
  'selectRequestFormData',
  'selectSelectedRequest',
  'selectSteps',
  'selectActiveStep',
  ({
    doUpdateRequestObject,
    doUpdateSectionValidity,
    doUpdateJDRequest,
    doUpdateRequestData,
    doSecondaryModalOpen,
    requestFormData,
    selectedRequest,
    steps,
    activeStep,
    isReadOnly,
    stepNo
  }) => {
    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);
    const convertFileNumbersToSelectFormat = (data) => data && data.map(obj => ({ label: obj.ormFileNumber, value: obj.ormFileNumber }));
    const convertValueToCheckbox = () => {
      switch (requestFormData?.request?.jdRequests?.[0]?.jurisdictionalDetermination) {
        case 'Preliminary':
          return ['Preliminary'];
        case 'Approved':
          return ['Approved'];
        case 'Preliminary+Approved':
          return ['Preliminary', 'Approved'];
        default:
          return [];
      }
    };

    const defaultValues = {
      projectName: requestFormData?.projectName,
      previousFileNumbersExist: requestFormData?.previousFileNumbers?.length > 0 ? 'true' : 'false',
      previousFileNumbers: requestFormData?.previousFileNumbers?.length > 0 ? convertFileNumbersToSelectFormat(requestFormData.previousFileNumbers) : [],
      requestReason: requestFormData?.request?.jdRequests?.[0]?.requestReasons?.map(reason => ({ value: reason.requestReason, label: reason.requestReason })) ?? [],
      projectDescription: requestFormData?.projectDescription ?? null,
      projectPurpose: requestFormData?.projectPurpose ?? null,
      natureOfActivity: requestFormData?.natureOfActivity ?? null,
      otherRequestReason: requestFormData?.request?.jdRequests?.[0]?.otherRequestReason ? requestFormData?.request?.jdRequests?.[0]?.otherRequestReason : null,
      JDTypeKnown: ((requestFormData?.request?.requestType === 'Jurisdictional Determination' && requestFormData?.request?.jdRequests?.[0]?.jurisdictionalDetermination && (requestFormData?.request?.jdRequests?.[0]?.jurisdictionalDetermination !== 'No JD Requested' && requestFormData?.request?.jdRequests?.[0]?.jurisdictionalDetermination !== 'Unsure')) ||
        (requestFormData?.request?.requestType === 'Delineation Report and Jurisdictional Determination')) ? 'true' : 'false',
      jurisdictionalDetermination: requestFormData?.request?.jdRequests?.[0]?.jurisdictionalDetermination ? convertValueToCheckbox() : [],
      relationshipToRequest: requestFormData?.request?.jdRequests?.[0]?.relationshipToRequest ? (relationsOptionsList.find(item => item === requestFormData?.request?.jdRequests?.[0]?.relationshipToRequest) ? requestFormData?.request?.jdRequests?.[0]?.relationshipToRequest : 'Other') : null,
      otherRelationshipToRequest: requestFormData?.request?.jdRequests?.[0]?.relationshipToRequest ? (!relationsOptionsList.find(item => item === requestFormData?.request?.jdRequests?.[0]?.relationshipToRequest) ? requestFormData?.request?.jdRequests?.[0]?.relationshipToRequest : null) : null,
    };

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors, isValid }, setValue, watch, getValues, trigger } = methods;

    const requestReason = watch('requestReason');
    const requestReasonsValues = requestReason?.map((reason) => reason.value);
    const previousFileNumbersExist = watch('previousFileNumbersExist', 'false');
    const previousFileNumbers = watch('previousFileNumbers', []);
    const JDTypeKnown = watch('JDTypeKnown');
    const jurisdictionalDetermination = watch('jurisdictionalDetermination');
    const relationshipToRequest = watch('relationshipToRequest');

    const convertCheckboxToValue = useCallback(() => {
      let type;

      if (jurisdictionalDetermination) {
        if (jurisdictionalDetermination.includes('Approved') && jurisdictionalDetermination.includes('Preliminary')) {
          type = 'Preliminary+Approved';
        } else if (jurisdictionalDetermination.includes('Approved')) {
          type = 'Approved';
        } else if (jurisdictionalDetermination.includes('Preliminary')) {
          type = 'Preliminary';
        } else {
          type = (selectedRequest === '1') ? 'No JD Requested' : 'Unsure';
        }
      }

      type && doUpdateJDRequest({ jurisdictionalDetermination: type });
    }, [doUpdateJDRequest, jurisdictionalDetermination, selectedRequest]);

    const handleChange = (e) => {

      if (e?.target?.name === 'previousFileNumbersExist' && e?.target?.value === 'false') {
        setValue('previousFileNumbers', []);
        doUpdateRequestData({ previousFileNumbers: [] });
        return;
      }

      if (!isReadOnly) {
        const values = getValues();
        const reasons = values?.requestReason?.map(val => ({ requestReason: val.value }));
        const jdValues = {
          requestReasons: reasons,
          otherRequestReason: values.otherRequestReason,
          relationshipToRequest: values?.relationshipToRequest === 'Other' ? values?.otherRelationshipToRequest : values?.relationshipToRequest
        };
        const prevFileNumbers = values?.previousFileNumbersExist === 'false' ? [] : values?.previousFileNumbers?.length > 0 ? values?.previousFileNumbers?.map(val => ({ ormFileNumber: val?.value?.toUpperCase() })) : [];
        doUpdateRequestData({ projectName: values?.projectName, previousFileNumbers: prevFileNumbers, projectDescription: values?.projectDescription, projectPurpose: values?.projectPurpose, natureOfActivity: values?.natureOfActivity });
        doUpdateJDRequest(jdValues);
      }
    };

    const handleJDTypeChange = (e) => {
      trigger('jurisdictionalDetermination');
      if (e.target.value === 'false') {
        setValue('jurisdictionalDetermination', [], { shouldValidate: true });
      }
      convertCheckboxToValue();
    };

    const handleSelectChange = (e) => {

      const results = e.map(num => isValidORMNumber(num.value));

      if (!results.every(Boolean)) {
        setValue('previousFileNumbers', e.length > 1 ? previousFileNumbers : []);
        doSecondaryModalOpen(statusModal, { status: 'Failed', msg: ormErrorMsg });
      }
    };

    useEffect(() => {
      if (selectedRequest === '1') {
        doUpdateRequestObject({ requestType: JDTypeKnown === 'false' ? 'Delineation Report' : 'Delineation Report and Jurisdictional Determination' });
      } else if (selectedRequest === '2') {
        doUpdateRequestObject({ requestType: 'Jurisdictional Determination' });
      }
    }, [JDTypeKnown, doUpdateRequestObject, selectedRequest]);

    useEffect(() => {
      doUpdateSectionValidity(JDFormGeneralProjectInformationMetadata.sectionName, isValid ? true : false, stepNo, isReadOnly);
    }, [isValid, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      jurisdictionalDetermination && convertCheckboxToValue();
    }, [jurisdictionalDetermination, convertCheckboxToValue]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <FieldHeader text='General Project Information' />
        <h6 className='border-bottom w-100 pb-2 mt-4'>Project</h6>
        <div className='ml-2'>
          <InputField label='Project Name' name='projectName' type='text' required tooltip='Please provide name identifying the proposed project, e.g., Landmark Plaza, Burned Hills Subdivision, or Edsall Commercial Center' readOnly={isReadOnly} onChange={handleChange} />
        </div>
        <div className='ml-2'>
          <MultiSelectInput name='requestReason' label='Reason(s) for Request (select all that apply):' multi options={requestreasons} handleChange={handleChange} required readOnly={isReadOnly} menuPlacement='bottom' />
        </div>
        {requestReasonsValues?.includes('Other') && (
          <div className='ml-2'>
            <TextAreaInput name='otherRequestReason' label='Type your reason for the request' required readOnly={isReadOnly} onChange={handleChange} />
          </div>
        )}
        <div className='ml-2'>
          <SelectInput name='relationshipToRequest' label='What is your relationship to the request?' required className='w-50' readOnly={isReadOnly} onChange={handleChange}>
            {relationsOptionsList.map((option, i) => <option key={i + 2} value={option}>{option}</option>)}
          </SelectInput>
        </div>
        {relationshipToRequest === 'Other' && (
          <div className='ml-2'>
            <TextAreaInput name='otherRelationshipToRequest' label='Type your relationship to the request' required readOnly={isReadOnly} onChange={handleChange} />
          </div>
        )}
        <div className='ml-2'>
          <SelectInput name='previousFileNumbersExist' label='Has the USACE previously issued a file number for any part of the project area?' required className='w-50' readOnly={isReadOnly} onChange={handleChange}>
            <option key='2' value='true'>Yes</option>
            <option key='3' value='false'>No</option>
          </SelectInput>
        </div>

        {previousFileNumbersExist === 'true' && (
          <div className='ml-2 mt-2'>
            <CreatableSelectInput name='previousFileNumbers' label='Previous File Number(s)' tooltip='Enter the file number and press "ENTER" or click on "Create ..."' placeholder='e.g. MVS-2023-12345, MVM-2020-1234 ...' required multi handleChange={handleSelectChange} handleBlur={handleChange} disabled={isReadOnly} />
          </div>
        )
        }
        <div className='ml-2'>
          <TextAreaInput name='projectDescription' label='Project Description' tooltip={projectDescriptionTooltipContent} readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <div className='ml-2'>
          <TextAreaInput name='projectPurpose' label='Project Purpose' tooltip={projectPurposeTooltipContent} readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <div className='ml-2'>
          <TextAreaInput name='natureOfActivity' label='Nature of Activity' tooltip={natureOfActivityTooltipContent} readOnly={isReadOnly} onChange={handleChange} required />
        </div>
        <div className='ml-2'>
          <SelectInput name='JDTypeKnown' label={selectedRequest === '1' ? 'Are you requesting that the USACE provide a written Jurisdictional Determination?' : 'Do you know what type of Jurisdictional Determination you require?'} required className='w-50 mb-3' onChange={handleJDTypeChange} readOnly={isReadOnly}>
            <option key='2' value='true'>Yes</option>
            <option key='3' value='false'>{selectedRequest === '1' ? 'No' : 'I am not sure and require additional information to inform my decision.'}</option>
          </SelectInput>
        </div>
        {JDTypeKnown === 'true' && (
          <fieldset className='mb-3 ml-2' id='jurisdictionalDetermination'>
            <label htmlFor='jurisdictionalDetermination'>Please specify the type of Jurisdictional Determination(s) (at least one required)<span className='asterisk-color'>*</span> <Tooltip name='jurisdictionalDetermination' content={JDTypeHelperContent} /></label>
            <CheckboxInput name='jurisdictionalDetermination' value='Preliminary' label='Preliminary' hideError disabled={isReadOnly} onChange={handleChange} />
            <CheckboxInput name='jurisdictionalDetermination' value='Approved' label='Approved' disabled={isReadOnly} onChange={handleChange} />
          </fieldset>
        )}
        {
          JDTypeKnown === 'false' && (
            <div className='ml-2'>
              <p><b>{selectedRequest === '1' ? 'The USACE will retain the delineation report in the administrative record. The USACE District may conduct a technical review of the report, conduct a field review, and/or may choose to provide a form letter or e-mail to the applicant concurring with the technical accuracy of the report (delineation concurrence).' : 'The USACE will conduct a field review as needed, discuss with you whether a written determination is appropriate, create a delineation report as needed, and retain the final delineation report in the administrative record as needed. The USACE District will provide the delineation report to you and a written determination if necessary.'}</b></p>
            </div>
          )
        }
      </FormProvider >
    );
  });

JDFormGeneralProjectInformation.metadata =
  JDFormGeneralProjectInformationMetadata;

export default JDFormGeneralProjectInformation;
