import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'redux-bundler-react';

import AddressValidator from '../../app-pages/Forms/components/Form/AddressValidator';
import InputField from './inputField';
import SelectInput from './selectInput';
import CreatableSelectInput from './creatableSelectInput';
import { Button } from '@trussworks/react-uswds';
import Icon from '@mdi/react';
import { mdiMinusCircleOutline, mdiPlusCircleOutline, mdiAccount } from '@mdi/js';

import { useFormContext } from 'react-hook-form';
import { salutations, phonetypes, states, countries } from '../../app-pages/Forms/input-forms/_helper.jsx';
import { formatUSPhoneNumber } from '../../utils/helpers.jsx';
import PhoneInputField from './phoneInputField.jsx';
import countryRegionData from 'country-region-data/dist/data-umd';

import './contactFieldsnew.scss';
import AddressSuggestInput from './addressSuggestInput.jsx';
import FieldHeader from '../../app-pages/Forms/components/Form/FieldHeader.jsx';

const ContactFields = connect(
  'selectUserProfileData',
  ({
    userProfileData,
    type, // lowerCamelCase format
    prefix, // boolean
    label,
    requiredFields,
    handleFieldChange,
    isReadOnly,
    showButton,
    showContactInfoSection = true,
    showSalutation = true,
    requireEmail = true,
  }) => {
    const { watch, setValue, register } = useFormContext();
    const [showPhoneTwo, setShowPhoneTwo] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);

    const fieldPrefix = useCallback(() => (type && prefix) ? type + '_' : '', [prefix, type]);

    register(`${fieldPrefix()}showPhoneTwo`);

    const phoneOneType = watch(`${fieldPrefix()}phoneOneType`);
    const phoneTwoType = watch(`${fieldPrefix()}phoneTwoType`);
    const phoneTwo = watch(`${fieldPrefix()}phoneTwo`);
    const address1 = watch(`${fieldPrefix()}address`);
    const address2 = watch(`${fieldPrefix()}addressTwo`);
    const city = watch(`${fieldPrefix()}city`);
    const state = watch(`${fieldPrefix()}state`);
    const zipcode = watch(`${fieldPrefix()}zipcode`);
    const country = watch(`${fieldPrefix()}country`);

    useEffect(() => {
      if ((phoneTwoType || phoneTwo) && !showPhoneTwo) {
        setShowPhoneTwo(() => true);
        setValue(`${fieldPrefix()}showPhoneTwo`, true);
      }
    }, [phoneTwoType, phoneTwo, showPhoneTwo, fieldPrefix, setValue]);


    const togglePhone = () => {
      if (!isReadOnly) {
        if (showPhoneTwo) {
          setShowPhoneTwo(() => false);
          setValue(`${fieldPrefix()}showPhoneTwo`, false);
          setValue(`${fieldPrefix()}phoneTwo`, '', { shouldValidate: true });
          setValue(`${fieldPrefix()}phoneTwoCountryCode`, '', { shouldValidate: true });
          setValue(`${fieldPrefix()}phoneTwoType`, '', { shouldValidate: true });
          setValue(`${fieldPrefix()}phoneTwoExtension`, '', { shouldValidate: true });
        } else {
          setShowPhoneTwo(() => true);
          setValue(`${fieldPrefix()}showPhoneTwo`, true);
          if (!requiredFields?.includes('Phone Number Two'))
            setValue(`${fieldPrefix()}phoneTwoCountryCode`, '');
          else
            setValue(`${fieldPrefix()}phoneTwoCountryCode`, '1');
        }
      }
    };

    const populateFromProfile = (e) => {
      e.preventDefault();
      setValue(`${fieldPrefix()}firstName`, userProfileData.firstName ? userProfileData.firstName : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}middleName`, userProfileData.middleName ? userProfileData.middleName : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}lastName`, userProfileData.lastName ? userProfileData.lastName : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}address`, userProfileData.address ? userProfileData.address : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}addressTwo`, userProfileData.addressTwo ? userProfileData.addressTwo : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}city`, userProfileData.city ? userProfileData.city : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}country`, userProfileData.country ? userProfileData.country : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}state`, userProfileData.state ? userProfileData.state : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}zipcode`, userProfileData.zipcode ? userProfileData.zipcode : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}company`, userProfileData.companyName ? userProfileData.companyName : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}phoneOneType`, 'Primary', { shouldValidate: true });
      setValue(`${fieldPrefix()}phoneOneCountryCode`, userProfileData.phoneCountryCode ? userProfileData.phoneCountryCode : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}phoneOne`, userProfileData?.phone ? (userProfileData?.phoneCountryCode === '1' ? formatUSPhoneNumber(userProfileData.phone) : userProfileData?.phone) : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}salutation`, userProfileData.salutation ? userProfileData.salutation : null, { shouldValidate: true });
      setValue(`${fieldPrefix()}emailAddress`, userProfileData.emailAddress ? userProfileData.emailAddress : null, { shouldValidate: true });
      handleFieldChange && handleFieldChange();
    };

    useEffect(() => {
      if (((country === null || country === '') && !requiredFields)
        || ((country === null || country === '') && requiredFields?.includes('Country'))) {
        setValue(`${fieldPrefix()}country`, 'US');
      }
    }, [setValue, fieldPrefix, requiredFields, country]);

    const addressValues = useMemo(() => ({
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zipcode: zipcode,
      country: country,
    }), [address1, address2, city, state, zipcode, country]);

    const handleBlur = (e) => {
      setValue(e.target.name, e.target.value.trim());
    };

    const regionHelper = useCallback((country) => {
      const selectedCountryData = countryRegionData?.find(regionCountry => regionCountry.countryShortCode === country);
      let regions = [];
      if (selectedCountryData) {
        regions = selectedCountryData.regions.map(region => ({
          value: region.shortCode,
          text: region.name
        }));
      }
      regions.push({ value: 'Other', text: 'Other' });
      regions.push({ value: 'None', text: 'None' });

      return regions;
    }, []);

    const handleCountryChange = useCallback((selectedCountry) => {
      const regions = regionHelper(selectedCountry);
      setStateOptions(regions);

      if (selectedCountry !== country) {
        setValue(`${fieldPrefix()}city`, '',{shouldValidate: true});
        setValue(`${fieldPrefix()}state`, '',{shouldValidate: true});
        setValue(`${fieldPrefix()}zipcode`, '', {shouldValidate: true});
      }
    }, [country, regionHelper, setStateOptions, setValue, fieldPrefix]);

    useEffect(() => {
      const regions = regionHelper(country);
      setStateOptions(regions);
    }, [country, regionHelper, setStateOptions]);

    useEffect(() => {
      const matchingOption = stateOptions?.find(option => option?.value === state);
      if (matchingOption) {
        setValue(`${fieldPrefix()}state`, matchingOption?.value, { shouldValidate: true });
      }
    }, [stateOptions, setValue, state, fieldPrefix]);

    return (
      <div className='container-fluid'>
        {type === 'Property Owner' && (
          <>
            <h6 className='border-bottom w-100 pb-2 mt-4'>{label} Parcel Number(s)</h6>
            <div className='row'>
              <div className='col-8'>
                <CreatableSelectInput name={`${fieldPrefix()}parcels`} label='Parcel Number(s)' placeholder='Enter any applicable parcel numbers' multi required={requiredFields ? requiredFields.includes('Parcel Number(s)') : false} disabled={isReadOnly} onChange={handleFieldChange} />
              </div>
            </div>
          </>)}
        <FieldHeader text={label} />
        <h6 className='border-bottom w-100 pb-2 mt-4'>{label} Name</h6>
        <div className='row'>
          <div className='col'>
            <div align='left'>
              {showButton ? (
                <Button className='use-profile-button' onClick={(e) => populateFromProfile(e)} tabIndex={0} disabled={isReadOnly}> <Icon title='Use Profile Data' path={mdiAccount} size={0.85} />USE PROFILE DATA </Button>) : (<></>)}
            </div>
            {showSalutation === true && <SelectInput name={`${fieldPrefix()}salutation`} label='Salutation' readOnly={isReadOnly} onChange={handleFieldChange}>
              {salutations.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
            </SelectInput>}
          </div>
          <div className='col'></div>
          <div className='col' align='right'>
          </div>
        </div>

        <div className='row'>
          <div className='col-4'>
            <InputField label='First Name' name={`${fieldPrefix()}firstName`} required={requiredFields ? requiredFields.includes('First Name') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </div>
          <div className='col-4'>
            <InputField label='Middle Name' name={`${fieldPrefix()}middleName`} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </div>
          <div className='col-4'>
            <InputField label='Last Name' name={`${fieldPrefix()}lastName`} required={requiredFields ? requiredFields.includes('Last Name') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </div>
        </div>

        <h6 className='border-bottom w-100 pb-2 mt-4'>{label} Address</h6>
        <div className='row'>
          <div className='col'>
            <AddressSuggestInput id='address' name={`${fieldPrefix()}address`} label='Address' required={requiredFields ? requiredFields.includes('Address One') : true} usOnly={false} onBlur={handleBlur} onChange={handleFieldChange} readOnly={isReadOnly} />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <InputField label='Address Line 2' name={`${fieldPrefix()}addressTwo`} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <SelectInput name={`${fieldPrefix()}country`} label='Country' required={requiredFields ? requiredFields.includes('Country') : true} readOnly={isReadOnly} onChange={(e) => handleCountryChange(e.target.value)}>
              {countries?.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
            </SelectInput>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'>
            <InputField label='City' name={`${fieldPrefix()}city`} required={requiredFields ? requiredFields.includes('City') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </div>
          {country === 'US' ? (
            <div className='col-4'>
              <SelectInput name={`${fieldPrefix()}state`} label='State' required={requiredFields ? requiredFields.includes('State') : true} readOnly={isReadOnly} onChange={handleFieldChange}>
                {states?.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
              </SelectInput>
            </div>
          ) : (
            <div className='col-4'>
              <SelectInput name={`${fieldPrefix()}state`} label='Region' required={requiredFields ? requiredFields.includes('State') : true} readOnly={isReadOnly}>
                {stateOptions?.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
              </SelectInput>
            </div>
          )}
          <div className='col-4'>
            <InputField type='text' label={country === 'US' ? 'Zip Code' : 'Postal code'} required={country === 'US' ? (requiredFields ? requiredFields.includes('Zip Code') : true) : false} name={`${fieldPrefix()}zipcode`} maxLength={country === 'US' ? 5 : 10} readOnly={isReadOnly} onChange={handleFieldChange} />
          </div>
        </div>
        <div className='mt-3'>
          <AddressValidator source={'form'} isDisabled={isReadOnly} address={addressValues} />
        </div>

        {showContactInfoSection && (
          <>
            <h6 className='border-bottom w-100 pb-2 mt-4'>{label} Contact Information</h6>

            <div className='row'>
              <div className='col-4 col-sm-4'>
                <SelectInput name={`${fieldPrefix()}phoneOneType`} label='Phone One Type' required={requiredFields ? requiredFields.includes('Phone One Type') : true} readOnly={isReadOnly} onChange={handleFieldChange}>
                  {phonetypes?.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
                </SelectInput>
              </div>
              <div className='col-4 col-sm-8'>
                <div className='row'>
                  <div>
                    <PhoneInputField phoneName={`${fieldPrefix()}phoneOne`} prefixName={`${fieldPrefix()}phoneOneCountryCode`} label='Phone Number One' required={requiredFields ? requiredFields.includes('Phone Number One') : true} readOnly={isReadOnly} onChange={handleFieldChange} onBlur={handleBlur} />
                  </div>
                  {phoneOneType === 'Business' &&
                    <div className='col col-sm-3'>
                      <InputField name={`${fieldPrefix()}phoneOneExtension`} label='Ext' maxLength={5} readOnly={isReadOnly} onChange={handleFieldChange} />
                    </div>
                  }
                  <div className='col'>
                    {!showPhoneTwo && <Icon onClick={togglePhone} onKeyUp={(e) => { e.key === 'Enter' && togglePhone(); }} className='add-phone-icon' path={mdiPlusCircleOutline} size={'24px'} tabIndex={0} />}
                  </div>
                </div>
              </div>
            </div>

            {showPhoneTwo &&
              <div className='row'>
                <div className='col-4 col-sm-4'>
                  <SelectInput name={`${fieldPrefix()}phoneTwoType`} label='Phone Two Type' required={requiredFields ? requiredFields.includes('Phone Two Type') : true} readOnly={isReadOnly} onChange={handleFieldChange}>
                    {phonetypes?.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
                  </SelectInput>
                </div>
                <div className='col-4 col-sm-8'>
                  <div className='row'>
                    <div>
                      <PhoneInputField phoneName={`${fieldPrefix()}phoneTwo`} prefixName={`${fieldPrefix()}phoneTwoCountryCode`} label='Phone Number Two' required={requiredFields ? requiredFields.includes('Phone Number Two') : true} readOnly={isReadOnly} onChange={handleFieldChange} onBlur={handleBlur} />
                    </div>
                    {phoneTwoType === 'Business' &&
                      <div className='col col-sm-3'>
                        <InputField name={`${fieldPrefix()}phoneTwoExtension`} label='Ext' maxLength={5} readOnly={isReadOnly} onChange={handleFieldChange} />
                      </div>
                    }
                    <div className='col'>
                      <Icon onClick={togglePhone} onKeyUp={(e) => { e.key === 'Enter' && togglePhone(); }} className='remove-phone-icon' path={mdiMinusCircleOutline} size={'24px'} tabIndex={0} />
                    </div>
                  </div>
                </div>
              </div>

            }
            <div className='row'>
              <div className='col'>
                <InputField type='tel' name={`${fieldPrefix()}faxPhone`} label='Fax Number' maxLength={10} readOnly={isReadOnly} onChange={handleFieldChange} />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <InputField name={`${fieldPrefix()}emailAddress`} label='Email Address' required={requireEmail && (requiredFields ? requiredFields.includes('Email Address') : true)} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <InputField name={`${fieldPrefix()}company`} label='Company' readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
              </div>
            </div>
          </>)}
      </div >
    );
  }
);

export default ContactFields;
